import { Paper, Stack } from "@mantine/core";
import { TermsConditionFormValues } from "./interface";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useFormTermsCondition from "./hooks/useFormTermsCondition";
import {
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../helpers";
import { AxiosError } from "axios";
import { validationSchema } from "./validation";
import VuiTextInput from "../../../components/vui-text-input";
import VuiRichTextEditor from "../../../components/vui-rich-text-editor";
import VuiActionForm from "../../../components/vui-action-form";
import { TERMS_CONDITION_PAGE_ID } from "../../../constant";

const TermsConditionModule = () => {
  const { t } = useTranslation();
  const { submitLoading, submitData, data, getData, isFetchingData } =
    useFormTermsCondition(t);
  const getDataRef = useRef(getData);
  const form = useForm<TermsConditionFormValues>({
    initialValues: {
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      content: "",
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    (values: TermsConditionFormValues) => {
      submitData(values)
        .then(() => {
          handleSaveSuccessResponse(t, t("text.termsCondition"), true);
        })
        .catch((error: AxiosError) => {
          handleSaveErrorResponse(t, error, form.setFieldError);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitData, t]
  );

  useMemo(() => {
    getDataRef.current(TERMS_CONDITION_PAGE_ID);
  }, []);

  useEffect(() => {
    if (data) {
      form.setValues(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage title={t("pages.termsCondition.title")} backUrl="/page" />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <VuiTextInput
              placeholder={t("form.metaTitle.placeholder")}
              label={t("form.metaTitle.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("meta_title")}
            />

            <VuiTextInput
              placeholder={t("form.metaDescription.placeholder")}
              label={t("form.metaDescription.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("meta_description")}
            />

            <VuiTextInput
              placeholder={t("form.metaKeyword.placeholder")}
              label={t("form.metaKeyword.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("meta_keyword")}
            />

            <VuiRichTextEditor
              label={t("form.content.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("content")}
            />
          </Stack>

          <VuiActionForm backUrl="/page" submitBtnLoading={submitLoading} />
        </form>
      </Paper>
    </>
  );
};

export default TermsConditionModule;
