import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { VuiConfirmationModalProps } from "./interface";
import { useTranslation } from "react-i18next";

const VuiConfirmationModal = (props: VuiConfirmationModalProps) => {
  const { t } = useTranslation();
  const {
    open = false,
    onClose,
    onConfirm,
    onSubmitLoading = false,
    description = t("modal.confirmation.delete"),
    title = t("text.confirmation"),
  } = props;

  return (
    <Modal opened={open} onClose={onClose} title={title}>
      <Stack>
        <Text color="gray">{description}</Text>

        <Group mt="sm" position="apart" grow>
          <Button variant="outline" color="gray" onClick={onClose}>
            {t("button.cancel")}
          </Button>
          <Button loading={onSubmitLoading} onClick={onConfirm} color="red">
            {t("button.delete")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default VuiConfirmationModal;
