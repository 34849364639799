import { CSSObject, MantineTheme } from "@mantine/core";
import { pxToRem } from "../../helpers";

export const styColorSchemeBox = (): CSSObject => ({
  position: "fixed",
  bottom: pxToRem(20),
  right: pxToRem(20),
  zIndex: 2,
});

export const styOutletContainer = (theme: MantineTheme): CSSObject => ({
  backgroundColor:
    theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
  width: "100%",
});
