import { Box, Switch, useMantineColorScheme } from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons";
import { useStyles } from "./style";

const ColorSchemeToggle = () => {
  const { classes, cx } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Box className={classes.root}>
      <IconSun
        className={cx(classes.icon, classes.iconLight)}
        size={18}
        stroke={1.5}
      />
      <IconMoonStars
        className={cx(classes.icon, classes.iconDark)}
        size={18}
        stroke={1.5}
      />
      <Switch
        checked={colorScheme === "dark"}
        onChange={() => toggleColorScheme()}
        size="md"
        color={colorScheme === "dark" ? "violet.6" : "gray"}
      />
    </Box>
  );
};

export default ColorSchemeToggle;
