import {
  Box,
  Burger,
  Header,
  MediaQuery,
  useMantineTheme,
} from "@mantine/core";
import { styOutletContainer, stySidebarBoxContainer } from "./style";
import Sidebar from "./components/sidebar";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "../../context/auth";
import { Navigate, Outlet } from "react-router-dom";
import _ from "lodash";
import usePassportService from "../../hooks/usePassportService";
import VuiScreenLoader from "../../components/vui-screen-loader";

const AuthorizedLayout = () => {
  const { user } = useAuth();
  const { isOnFetchingUser, logout } = usePassportService();
  const theme = useMantineTheme();
  const [openedNavbar, navbarHandlers] = useDisclosure(false);

  if (_.isEmpty(user) && !isOnFetchingUser) {
    return <Navigate to={"/auth/login"} />;
  }

  if (isOnFetchingUser) {
    return <VuiScreenLoader />;
  }

  return (
    <>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Header height={60} p="xs">
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={openedNavbar}
                onClick={navbarHandlers.toggle}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
          </Box>
        </Header>
      </MediaQuery>

      <Box sx={stySidebarBoxContainer}>
        <Sidebar opened={openedNavbar} logout={logout} />

        <Box px="md" pt="md" pb={50} sx={styOutletContainer}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AuthorizedLayout;
