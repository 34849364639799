import api from "../../services/api";
import { IUpdateRepository } from "../../entities/repository";
import { EditVariantPriceFormValues } from "../../modules/product/components/edit-variant-price-modal/interface";
import { ProductVariant } from "../../entities/product/product-variant";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import {AxiosPromise} from "axios";

interface IMerchantVariantRepository
  extends IUpdateRepository<EditVariantPriceFormValues, ProductVariant> {
  updateStatus(id: string, payload: {
    is_active: number
  }): AxiosPromise<any>;
}

const MerchantVariantRepository: IMerchantVariantRepository = {
  update(id, payload) {
    return api.put(`/api/merchant/variant/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  updateStatus(id, payload) {
    return api.put(`/api/merchant/variant/update-status/${id}`, payload, {
      cancelToken:
      cancelTokenHandlerObject[this.updateStatus.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(MerchantVariantRepository);

export default MerchantVariantRepository;
