import {Paper, Radio, Stack} from "@mantine/core";
import {MerchantFormModuleProps, MerchantFormValues} from "./interface";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useMemo, useRef} from "react";
import VuiHeaderPage from "../../../components/vui-header-page";
import {useForm, yupResolver} from "@mantine/form";
import {validationSchema} from "./validation";
import useFormMerchant from "../hooks/useFormMerchant";
import {handleSaveErrorResponse, handleSaveSuccessResponse} from "../../../helpers";
import {AxiosError} from "axios";
import VuiTextInput from "../../../components/vui-text-input";
import VuiActionForm from "../../../components/vui-action-form";
import VuiSingleDropzone from "../../../components/vui-single-dropzone";
import {Media} from "../../../entities/media";
import VuiCurrencyInput from "../../../components/vui-currency-input";
import {EnumMerchantFeeType} from "../../../entities/merchant";
import VuiNumberInput from "../../../components/vui-number-input";
import {IconPercentage} from "@tabler/icons";


const MerchantFormModule = (props: MerchantFormModuleProps) => {
    const { id } = props;
    const { t } = useTranslation();
    const title = id ? t("pages.merchant.detail.title") : t("pages.merchant.add.title");

    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    const { isFetchingData, submitData, data, getData, submitLoading } = useFormMerchant(t);

    const getDataRef = useRef(getData);
    const form = useForm<MerchantFormValues>({
        initialValues: {
            name: "",
            photo_id: null,
            photo: null,
            fee_type_id: EnumMerchantFeeType.AMOUNT,
            fee_amount: 0,
            percentage: 0,
            max_fee: 0
        },

        validate: yupResolver(validationSchema(t)),
    });

    const handleSubmit = useCallback(
        async (values: MerchantFormValues) => {
            const normalizeValue = {
                id: id,
                photo_id:
                    typeof values.photo_id === "number"
                        ? values.photo_id
                        : values.photo_id?.id,
                name: values.name,
                fee_type_id: values.fee_type_id,
                fee_amount: values.fee_amount
            };

            if (values.fee_type_id === EnumMerchantFeeType.AMOUNT) {
                Object.assign(normalizeValue, {
                    fee_amount: values.fee_amount
                });
            }

            if (values.fee_type_id === EnumMerchantFeeType.PERCENTAGE) {
                Object.assign(normalizeValue, {
                    percentage: values.percentage,
                    max_fee: values.max_fee
                });
            }

            submitData(normalizeValue)
                .then(() => {
                    handleSaveSuccessResponse(t, t("text.merchant"), id ? true : false);

                    if (!id) navigateRef.current("/merchant");
                })
                .catch((error: AxiosError) => {
                    handleSaveErrorResponse(t, error);
                });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [t]
    );

    const handleChangeBannerImage = useCallback(
        (value: Media | null) => {
            form.setFieldValue("photo_id", value ? value.id : value);
        },
        [form]
    );

    useMemo(() => {
        if (id) {
            getDataRef.current(Number(id));
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            form.setValues({
                ...data,
                fee_type_id: String(data.fee_type_id) as EnumMerchantFeeType,
                photo_id: data.photo,
                percentage: data.percentage ?? 0,
                fee_amount: data.fee_amount ?? 0,
                max_fee: data.max_fee ?? 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <VuiHeaderPage title={title} backUrl={"/merchant"} />

            <Paper shadow="xs" radius="md" p="xl">
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Stack>
                        <VuiTextInput
                            skeletonLoading={isFetchingData}
                            label={t("form.name.label")}
                            placeholder={t("form.name.placeholder")}
                            {...form.getInputProps("name")}
                        />

                        <Radio.Group
                            label={t("form.fee_type.label")}
                            {...form.getInputProps("fee_type_id")}
                        >
                            <Radio
                                value={EnumMerchantFeeType.PERCENTAGE}
                                label={t("text.percentage")}
                            />
                            <Radio
                                value={EnumMerchantFeeType.AMOUNT}
                                label={t("text.amount")}
                            />
                        </Radio.Group>

                        {
                            form.values.fee_type_id === EnumMerchantFeeType.PERCENTAGE ? (
                                <>
                                    <VuiNumberInput
                                        label={t("form.percentage.label")}
                                        placeholder={t("form.percentage.placeholder")}
                                        max={100}
                                        min={0}
                                        icon={<IconPercentage size={18} />}
                                        {...form.getInputProps("percentage")}
                                    />
                                </>
                            ) : (
                                <VuiCurrencyInput
                                    label={t("form.fee_amount.label")}
                                    placeholder={t("form.fee_amount.placeholder")}
                                    {...form.getInputProps("fee_amount")}
                                />
                            )
                        }

                        <VuiSingleDropzone
                            label={t("form.image.label")}
                            skeletonLoading={isFetchingData}
                            onChangeImage={(value) => handleChangeBannerImage(value)}
                            onRemoveImage={() => handleChangeBannerImage(null)}
                            {...form.getInputProps("photo_id")}
                        />

                    </Stack>

                    <VuiActionForm backUrl="/merchant" submitBtnLoading={submitLoading} />
                </form>
            </Paper>
        </>
    )
}

export default MerchantFormModule