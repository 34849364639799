import * as Yup from "yup";
import { TFunction } from "react-i18next";
import {EnumMerchantFeeType} from "../../../entities/merchant";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    name: Yup.string().required().label(t("form.name.label")),
      fee_type_id: Yup.mixed().required().label(t("form.fee_type.label")),
    percentage: Yup
        .number()
        .when("fee_type_id", {
          is: EnumMerchantFeeType.PERCENTAGE,
          then: Yup.number().required().label(t("form.percentage.label"))
        }),
      max_fee: Yup
          .number()
          .when("fee_type_id", {
              is: EnumMerchantFeeType.PERCENTAGE,
              then: Yup.number().label(t("form.max_fee.label"))
          }),
      fee_amount: Yup
          .number()
          .when("fee_type_id", {
              is: EnumMerchantFeeType.AMOUNT,
              then: Yup.number().required().label(t("form.fee_amount.label"))
          }),
  };

  return Yup.object().shape(shapes);
};
