import { useCallback, useEffect, useState } from "react";
import passportService from "../services/passport.service";
import { useAuth } from "../context/auth";
import { AxiosError } from "axios";
import _ from "lodash";
import { User } from "../entities/user";

const usePassportService = () => {
  const { dispatch, user } = useAuth();
  const [isOnSignIn, setIsOnSignIn] = useState<boolean>(false);
  const [isOnFetchingUser, setIsOnFetchingUser] = useState(true);

  const fetchUser = useCallback(async () => {
    if (!isOnFetchingUser) {
      return;
    }

    const accessToken = passportService.getAccessToken();

    console.log(accessToken)

    if (accessToken && _.isEmpty(user)) {
      await passportService
        .fetchUser()
        .then((response: any) => {
          dispatch({
            type: "UPDATE_USER",
            payload: response.data.data,
          });
        })
        .catch(() => {});
    }

    setIsOnFetchingUser(false);
  }, [isOnFetchingUser, dispatch, user]);

  const login = async (
    username: string,
    password: string,
    scope: string = "*"
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsOnSignIn(true);

      await passportService
        .login(username, password, scope)
        .then((response) => {
          setIsOnFetchingUser(true);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });

      setIsOnSignIn(false);
    });
  };

  const logout = useCallback(async () => {
    await passportService
      .logout()
      .then(() => {
        dispatch({
          type: "UPDATE_USER",
          payload: {} as User,
        });
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [isOnFetchingUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isOnFetchingUser,
    isOnSignIn,
    login,
    logout,
    fetchUser,
  };
};

export default usePassportService;
