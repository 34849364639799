import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../../components/vui-helmet";
import PurchaseModule from "../../../../modules/page/purchase";

const AppPurchasePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.purchase.metaTitle")} />

      <PurchaseModule />
    </>
  );
};

export default AppPurchasePage;
