import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/vui-helmet";
import ProfileModule from "../../../modules/profile";

const AppProfilePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.profile.metaTitle")} />

      <ProfileModule />
    </>
  );
};

export default AppProfilePage;
