import { Button, Group, Stack, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LoginFormValues } from "./interface";
import { validationSchema } from "./validation";
import { Link } from "react-router-dom";
import usePassportService from "../../hooks/usePassportService";
import { AxiosError } from "axios";
import { handleSaveErrorResponse } from "../../helpers";
import AuthModule from "../auth";
import VuiTextInput from "../../components/vui-text-input";
import VuiPasswordInput from "../../components/vui-password-input";

const LoginModule = () => {
  const { t } = useTranslation();
  const { login, isOnSignIn } = usePassportService();

  const form = useForm<LoginFormValues>({
    initialValues: {
      email: "",
      password: "",
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    async (values: LoginFormValues) => {
      await login(values.email, values.password).catch((error: AxiosError) => {
        handleSaveErrorResponse(t, error, form.setFieldError);
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [login, t]
  );

  return (
    <AuthModule
      title={t("pages.login.title")}
      description={t("pages.login.description")}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <VuiTextInput
            label={t("form.email.label")}
            placeholder={t("form.email.placeholder")}
            {...form.getInputProps("email")}
          />

          <VuiPasswordInput
            label={t("form.password.label")}
            placeholder={t("form.password.placeholder")}
            {...form.getInputProps("password")}
          />

          <Group position="right" mt={-8}>
            <Text<typeof Link>
              to={"/auth/forgot-password"}
              component={Link}
              sx={{
                cursor: "pointer",
              }}
              color="gray.7"
            >
              {t("text.forgotPassword")}
            </Text>
          </Group>
        </Stack>

        <Group position="center" mt="xl">
          <Button loading={isOnSignIn} fullWidth size="md" type="submit">
            {t("button.login")}
          </Button>
        </Group>
      </form>
    </AuthModule>
  );
};

export default LoginModule;
