import { Text } from "@mantine/core";
import { VuiFormErrorProps } from "./interface";

const VuiFormError = (props: VuiFormErrorProps) => {
  const { message } = props;

  return <Text color="red">{message}</Text>;
};

export default VuiFormError;
