import { Button, Group } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ForgotPasswordFormValues } from "./interface";
import { validationSchema } from "./validation";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { handleSaveErrorResponse } from "../../helpers/api-response.helper";
import AuthRepository from "../../repositories/auth-repository";
import { useIsMounted } from "usehooks-ts";
import AuthModule from "../auth";
import VuiTextInput from "../../components/vui-text-input";

const ForgotPasswordModule = () => {
  const hostUrl = process.env.REACT_APP_URL;
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useForm<ForgotPasswordFormValues>({
    initialValues: {
      email: "",
      url: "",
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    async (values: ForgotPasswordFormValues) => {
      setIsLoading(true);

      const formData: ForgotPasswordFormValues = {
        email: values.email,
        url: `${hostUrl}/auth/reset-password`,
      };

      await AuthRepository.forgotPassword(formData)
        .then(() => {
          if (isMounted()) {
            setIsLoading(false);
            navigateRef.current("/auth/forgot-password-success");
          }
        })
        .catch((err: AxiosError) => {
          if (isMounted()) {
            setIsLoading(false);
            handleSaveErrorResponse(t, err, form.setFieldError);
          }
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [hostUrl, isMounted, t]
  );

  return (
    <AuthModule title={t("text.forgotPassword")}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <VuiTextInput
          label={t("form.email.label")}
          placeholder={t("form.email.placeholder")}
          {...form.getInputProps("email")}
        />

        <Group position="right" mt="xl">
          <Button
            component={Link}
            to={"/auth/login"}
            size="md"
            variant="outline"
            color="gray"
          >
            {t("button.cancel")}
          </Button>
          <Button loading={isLoading} size="md" type="submit">
            {t("button.submit")}
          </Button>
        </Group>
      </form>
    </AuthModule>
  );
};

export default ForgotPasswordModule;
