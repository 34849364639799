import {TFunction} from "react-i18next";
import {useState} from "react";
import {KeyString} from "../../../../entities/repository";
import {BaseQueryParams} from "../../../../entities/query-params";
import AdminReportMerchantRepository from "../../../../repositories/admin/report-merchant-repository";
import {MerchantProfit} from "../../../../entities/report/merchant-profit";


const useGetReportMerchant = (t: TFunction) => {
    const [dataSource, setDataSource] = useState<MerchantProfit[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const loadData = (params: KeyString | BaseQueryParams) => {
        setLoading(true);

        AdminReportMerchantRepository.merchantProfit(params)
            .then((response) => {
                const { data: responseData, meta } = response.data;
                setDataSource(responseData);
                setTotal(meta.last_page);
            })
            .catch((err) => {
                const errMessage =
                    err?.response?.message || t("notification.error.default");
                setError(errMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        dataSource,
        total,
        error,
        loading,
        loadData,
    };
}

export default useGetReportMerchant;