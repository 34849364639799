import { useCallback, useState } from "react";
import AccountRepository from "../../../repositories/account-repository";
import { ChangePasswordFormValues } from "../interface";
import { AxiosError } from "axios";

const useFormChangePassword = () => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitData = useCallback((values: ChangePasswordFormValues) => {
    setSubmitLoading(true);
    return new Promise((resolve, reject) => {
      AccountRepository.changePassword(values)
        .then(() => {
          resolve(true);
        })
        .catch((error: AxiosError) => {
          reject(error);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    });
  }, []);

  return {
    submitData,
    submitLoading,
  };
};

export default useFormChangePassword;
