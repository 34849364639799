import { Button, Group, Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResetPasswordFormValues, ResetPasswordProps } from "./interface";
import { validationSchema } from "./validation";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { handleSaveErrorResponse } from "../../helpers";
import AuthRepository from "../../repositories/auth-repository";
import { useIsMounted } from "usehooks-ts";
import AuthModule from "../auth";
import VuiPasswordInput from "../../components/vui-password-input";

const ResetPasswordModule = (props: ResetPasswordProps) => {
  const { token, email } = props;
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useForm<ResetPasswordFormValues>({
    initialValues: {
      email: email,
      token: token,
      password: "",
      password_confirmation: "",
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    async (values: ResetPasswordFormValues) => {
      setIsLoading(true);

      const formData: ResetPasswordFormValues = {
        ...values,
        password_confirmation: values.password_confirmation,
        password: values.password,
      };

      await AuthRepository.resetPassword(formData)
        .then(() => {
          if (isMounted()) {
            setIsLoading(false);
            navigateRef.current("/auth/reset-password-success");
          }
        })
        .catch((err: AxiosError) => {
          if (isMounted()) {
            setIsLoading(false);
            handleSaveErrorResponse(t, err, form.setFieldError);
          }
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, t]
  );

  return (
    <AuthModule title={t("pages.resetPassword.title")}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <VuiPasswordInput
            label={t("form.newPassword.label")}
            placeholder={t("form.newPassword.placeholder")}
            {...form.getInputProps("password")}
          />

          <VuiPasswordInput
            label={t("form.newPasswordConfirmation.label")}
            placeholder={t("form.newPasswordConfirmation.placeholder")}
            {...form.getInputProps("password_confirmation")}
          />
        </Stack>

        <Group position="center" mt="xl">
          <Button loading={isLoading} fullWidth size="md" type="submit">
            {t("button.resetPassword")}
          </Button>
        </Group>
      </form>
    </AuthModule>
  );
};

export default ResetPasswordModule;
