import { VuiNumberInputProps } from "./interface";
import { NumberInput, Skeleton } from "@mantine/core";

const VuiNumberInput = (props: VuiNumberInputProps) => {
  const { skeletonLoading = false, ...inputProps } = props;

  return (
    <Skeleton visible={skeletonLoading}>
      <NumberInput {...inputProps} />
    </Skeleton>
  );
};

export default VuiNumberInput;
