import UserModule from "../../../modules/user";
import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";

const AppUserPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.user.metaTitle")} />

      <UserModule />
    </>
  );
};

export default AppUserPage;
