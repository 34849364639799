import { createStyles } from "@mantine/core";
import { pxToRem } from "../../helpers";

export const useStyles = createStyles((theme) => ({
  table: {
    "& tbody tr, & thead tr": {
      whiteSpace: "nowrap",
    },
  },
  header: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",
    borderRadius: pxToRem(4),

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },

    "tr > th:first-of-type": {
      width: pxToRem(40),
    },
  },

  noDataWrapper: {
    textAlign: "center",
  },

  boxLoader: {
    height: pxToRem(140),
    textAlign: "center",
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));
