import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ForgotPasswordModule from "../../../modules/forgot-password";

const AuthForgotPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.forgotPassword.metaTitle")} />

      <ForgotPasswordModule />
    </>
  );
};

export default AuthForgotPasswordPage;
