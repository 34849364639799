import api from "../../services/api";
import { ISelectRepository } from "../../entities/repository";
import { Product } from "../../entities/product/product";
import { createCancelTokenHandler } from "../../services/cancel-token.service";

interface IAdminCategoryRepository<T> extends ISelectRepository<T> {}

const AdminCategoryRepository: IAdminCategoryRepository<Product> = {
  select: function (params) {
    return api.get(`/api/admin/select/category`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AdminCategoryRepository);

export default AdminCategoryRepository;
