import { DatePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import { VuiDatePickerProps } from "./interface";
import { pxToRem } from "../../helpers";
import { Box, Skeleton } from "@mantine/core";

const VuiDatepicker = (props: VuiDatePickerProps) => {
  const {
    fullWidth = true,
    skeletonLoading = false,
    icon = <IconCalendar size={20} />,
    inputFormat = "DD MMMM YYYY",
    value,
    ...other
  } = props;

  return (
    <Skeleton visible={skeletonLoading}>
      <Box>
        <DatePicker
          {...other}
          sx={{
            maxWidth: fullWidth ? "100%" : pxToRem(240),
          }}
          inputFormat={inputFormat}
          value={value ? new Date(value) : new Date()}
          icon={icon}
        />
      </Box>
    </Skeleton>
  );
};

export default VuiDatepicker;
