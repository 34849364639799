import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PromoFormModule from "../../../../modules/promo/form";

const AppPromoFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <VuiHelmet title={t("pages.promo.detail.metaTitle")} />

      <PromoFormModule id={id} />
    </>
  );
};

export default AppPromoFormPage;
