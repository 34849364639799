import {useTranslation} from "react-i18next";
import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import useGetMerchant from "./hooks/useGetMerchant";
import {useDebounce} from "usehooks-ts";
import {BaseSort, OrderDirection} from "../../entities/query-params";
import {Button, Group, MediaQuery, SelectItem} from "@mantine/core";
import {TableColumn} from "../../components/vui-table/interface";
import {Link} from "react-router-dom";
import {DEFAULT_PER_PAGE} from "../../constant";
import VuiHeaderPage from "../../components/vui-header-page";
import VuiSearch from "../../components/vui-search";
import VuiSelect from "../../components/vui-select";
import VuiTable from "../../components/vui-table";
import {IconPlus} from "@tabler/icons";
import VuiActionTable from "../../components/vui-action-table";
import VuiConfirmationModal from "../../components/vui-confirmation-modal";
import useFormMerchant from "./hooks/useFormMerchant";
import {EnumMerchantFeeType} from "../../entities/merchant";

const MerchantModule = () => {
    const { t } = useTranslation();

    const { dataSource, loadData, loading, total } = useGetMerchant(t);
    const loadDataRef = useRef(loadData);

    const [page, setPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedValue = useDebounce<string>(searchValue, 250);

    const [sort, setSort] = useState<BaseSort>({
        order_by: "name",
        sorted_by: "asc",
    });

    const sortOptions: SelectItem[] = [
        {
            value: "name-asc",
            label: t("select.sortAsc", { item: t("text.name") }),
        },
        {
            value: "name-desc",
            label: t("select.sortDesc", { item: t("text.name") }),
        },
    ];

    const columns: TableColumn[] = [
        { key: "name", dataIndex: "name", title: t("text.name") },
        {
            key: "fee_type_id",
            dataIndex: "fee_type_id",
            title: t("text.fee_type"),
            render: (value, item) => (
                <>
                    {item.fee_type_id === Number(EnumMerchantFeeType.PERCENTAGE) ? t("text.percentage") : t("text.amount")}
                </>
            )
        },
        {
            key: "transaction_fee",
            dataIndex: "transaction_fee",
            title: t("text.fee_amount"),
            render: (value, item) => (
                <>
                    {
                        item.fee_type_id === Number(EnumMerchantFeeType.PERCENTAGE) ? (
                            <>
                                {value} %
                            </>
                        ) : (
                            <>
                                Rp {value}
                            </>
                        )
                    }
                </>
            )
        },
        {
            key: "action",
            dataIndex: "id",
            title: "",
            render: (value, item) => (
                <VuiActionTable
                    detailUrl={`/merchant/${value}`}
                    onDelete={() => handleDelete(Number(value))}
                    canDelete={item.can_delete}
                />
            ),
        },
    ];

    const { deleteData, deleteLoading } = useFormMerchant(t);
    const [selectedId, setSelectedId] = useState<number>(0);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const handleCloseConfirmModal = useCallback(() => {
        setOpenConfirmModal(false);
    }, []);
    const handleDelete = useCallback((id: number) => {
        setSelectedId(id);
        setOpenConfirmModal(true);
    }, []);

    const handleConfirmDelete = useCallback(() => {
        deleteData(selectedId).then(() => {
            handleCloseConfirmModal();

            loadDataRef.current({
                page: page,
                per_page: DEFAULT_PER_PAGE,
                search: debouncedValue,
                sorted_by: sort.sorted_by,
                order_by: sort.order_by,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId, debouncedValue, sort, page]);

    const handleChangePage = useCallback(
        (page: number) => {
            setPage(page);

            loadDataRef.current({
                page: page,
                per_page: DEFAULT_PER_PAGE,
                search: debouncedValue,
                sorted_by: sort.sorted_by,
                order_by: sort.order_by
            });
        },
        [sort, debouncedValue]
    );

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.currentTarget.value);
    }, []);

    const handleChangeSort = useCallback(
        (value: string) => {
            const splitValue = value.split("-");
            setSort({
                order_by: splitValue[0],
                sorted_by: splitValue[1] as OrderDirection,
            });

            loadDataRef.current({
                page: page,
                per_page: DEFAULT_PER_PAGE,
                search: debouncedValue,
                sorted_by: splitValue[1] as OrderDirection,
                order_by: splitValue[0]
            });
        },
        [page, debouncedValue]
    );

    useEffect(() => {
        loadDataRef.current({
            page: 1,
            per_page: DEFAULT_PER_PAGE,
            search: debouncedValue,
            sorted_by: sort.sorted_by,
            order_by: sort.order_by,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
        <>
            <VuiConfirmationModal
                open={openConfirmModal}
                onClose={handleCloseConfirmModal}
                onConfirm={handleConfirmDelete}
                onSubmitLoading={deleteLoading}
            />

            <VuiHeaderPage title={t("pages.merchant.title")}>
                <Group>
                    <Button
                        leftIcon={<IconPlus size={18} />}
                        to={"/merchant/add"}
                        component={Link}
                    >
                        {t("button.add")}
                    </Button>
                </Group>
            </VuiHeaderPage>

            <Group position="apart" mb="md">
                <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                    <VuiSearch value={searchValue} onChange={handleSearch} />
                </MediaQuery>

                <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                    <VuiSelect
                        placeholder={t("select.sort")}
                        onChange={handleChangeSort}
                        data={sortOptions}
                    />
                </MediaQuery>
            </Group>

            <VuiTable
                totalPage={total}
                onChangePage={handleChangePage}
                isLoading={loading}
                dataSource={dataSource}
                columns={columns}
                page={page}
            />
        </>
    )
}

export default MerchantModule