import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons";

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
  DEFAULT = "default",
}

export const openNotification = (
  message: string,
  type: NotificationType = NotificationType.DEFAULT
) => {
  const isDefault = type === NotificationType.DEFAULT;
  const icon = type === NotificationType.ERROR ? <IconX /> : <IconCheck />;
  const color = type === NotificationType.ERROR ? "red" : "teal";

  showNotification({
    message: message,
    autoClose: 5000,
    icon: !isDefault && icon,
    color: isDefault ? "" : color,
  });
};
