import api from "../services/api";
import { IFindAllRepository, IFindOneRepository } from "../entities/repository";
import { Page } from "../entities/page";
import { createCancelTokenHandler } from "../services/cancel-token.service";
import { AxiosPromise } from "axios";
import { Resource } from "../entities/resource";
import { PrivacyPolicyFormValues } from "../modules/page/privacy-policy/interface";
import { TermsConditionFormValues } from "../modules/page/terms-condition/interface";
import { PurchaseFormValues } from "../modules/page/purchase/interface";
import { HomeFormValues } from "../modules/page/home/interface";

interface IPageRepository<T, R>
  extends IFindAllRepository<T, R>,
    IFindOneRepository<T, any> {
  privacyPolicy(
    payload: PrivacyPolicyFormValues
  ): AxiosPromise<Resource<PrivacyPolicyFormValues>>;
  termsCondition(
    payload: TermsConditionFormValues
  ): AxiosPromise<Resource<TermsConditionFormValues>>;
  purchase(
    payload: PurchaseFormValues
  ): AxiosPromise<Resource<PurchaseFormValues>>;
  home(payload: HomeFormValues): AxiosPromise<Resource<HomeFormValues>>;
}

const PageRepository: IPageRepository<Page, Page> = {
  findAll: function (params) {
    return api.get(`/api/merchant/page`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/merchant/page/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  privacyPolicy(payload) {
    return api.post(`/api/merchant/page/privacy-policy`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.privacyPolicy.name
        ].handleRequestCancellation().token,
    });
  },
  termsCondition(payload) {
    return api.post(`/api/merchant/page/terms-condition`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.termsCondition.name
        ].handleRequestCancellation().token,
    });
  },
  purchase(payload) {
    return api.post(`/api/merchant/page/purchase`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.purchase.name].handleRequestCancellation()
          .token,
    });
  },
  home(payload) {
    return api.post(`/api/merchant/page/home`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.home.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(PageRepository);

export default PageRepository;
