import { Button, Group, MediaQuery, SelectItem } from "@mantine/core";
import VuiTable from "../../components/vui-table";
import { TableColumn } from "../../components/vui-table/interface";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VuiSearch from "../../components/vui-search";
import { useDebounce } from "usehooks-ts";
import { DEFAULT_PER_PAGE } from "../../constant";
import { Link } from "react-router-dom";
import VuiHeaderPage from "../../components/vui-header-page";
import useGetProduct from "./hooks/useGetProduct";
import VuiSelect from "../../components/vui-select";
import { BaseSort, OrderDirection } from "../../entities/query-params";
import useGetSelectOption from "../../hooks/useGetSelectOption";
import {useIsAdmin} from "../../context/auth";

const ProductModule = () => {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();
  const { dataSource, loadData, loading, total } = useGetProduct(t);
  const loadDataRef = useRef(loadData);

  const { categoryData, loadCategoryData } = useGetSelectOption();
  const loadCategoryDataRef = useRef(loadCategoryData);

  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [category, setCategory] = useState<string | null>(null);
  const debouncedValue = useDebounce<string>(searchValue, 250);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const [sort, setSort] = useState<BaseSort>({
    order_by: "name",
    sorted_by: "asc",
  });

  const sortOptions: SelectItem[] = [
    {
      value: "name-asc",
      label: t("select.sortAsc", { item: t("text.name") }),
    },
    {
      value: "name-desc",
      label: t("select.sortDesc", { item: t("text.name") }),
    },
  ];

  const columns: TableColumn[] = [
    { key: "name", dataIndex: "name", title: t("text.name") },
    {
      key: "category_name",
      dataIndex: "category_name",
      title: t("text.category"),
    },
    {
      key: "action",
      dataIndex: "id",
      title: "",
      render: (value) => (
        <Group position="right" spacing="xs">
          <Button
            variant="outline"
            to={`/product/${value}`}
            size="xs"
            component={Link}
          >
            {t("button.detail")}
          </Button>
        </Group>
      ),
    },
  ];

  if (isAdmin) {
    columns.splice(2, 0, {
      key: "provider",
      dataIndex: "provider",
      title: t("text.provider")
    })
  } else {
    columns.splice(2, 0, {
      key: "total_active_inactive",
      dataIndex: "id",
      title: t("text.total_active_inactive"),
      render: (value, item) => (
          <>
            {item.total_variant_active} / {item.total_variant}
          </>
      )
    })
  }

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        category: category,
      });
    },
    [sort, debouncedValue, category]
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleChangeCategory = useCallback(
    (value: string | null) => {
      setCategory(value);
      setPage(1);

      loadDataRef.current({
        page: 1,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        category: value,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
      });
    },
    [sort, debouncedValue]
  );

  const handleChangeSort = useCallback(
    (value: string) => {
      const splitValue = value.split("-");
      setSort({
        order_by: splitValue[0],
        sorted_by: splitValue[1] as OrderDirection,
      });

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: splitValue[1] as OrderDirection,
        order_by: splitValue[0],
        category: category,
      });
    },
    [page, category, debouncedValue]
  );

  useEffect(() => {
    if (!hasInitialized) {
      loadCategoryDataRef.current();

      setHasInitialized(true);
    }

    loadDataRef.current({
      page: 1,
      per_page: DEFAULT_PER_PAGE,
      search: debouncedValue,
      sorted_by: sort.sorted_by,
      order_by: sort.order_by,
      category: category,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <>
      <VuiHeaderPage title={t("pages.product.title")} />

      <Group position="apart" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSearch value={searchValue} onChange={handleSearch} />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.sort")}
            onChange={handleChangeSort}
            data={sortOptions}
          />
        </MediaQuery>
      </Group>

      <Group position="left" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.category")}
            onChange={handleChangeCategory}
            data={categoryData}
          />
        </MediaQuery>
      </Group>

      <VuiTable
        totalPage={total}
        onChangePage={handleChangePage}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        page={page}
      />
    </>
  );
};

export default ProductModule;
