import { Button, Group } from "@mantine/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VuiActionFormProps } from "./interface";

const VuiActionForm = (props: VuiActionFormProps) => {
  const { t } = useTranslation();
  const {
    submitBtnLabel = t("button.submit"),
    backBtnLabel = t("button.back"),
    submitBtnLoading = false,
    backUrl,
    forSubmit = true,
  } = props;

  return (
    <Group position="right" mt="md">
      {backUrl && (
        <Button component={Link} to={backUrl} variant="outline" color="gray">
          {backBtnLabel}
        </Button>
      )}

      {forSubmit && (
        <Button loading={submitBtnLoading} type="submit">
          {submitBtnLabel}
        </Button>
      )}
    </Group>
  );
};

export default VuiActionForm;
