import {Button, Group, Paper, Stack, Text} from "@mantine/core";
import { ProductFormModuleProps, ProductFormValues } from "./interface";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import VuiSingleDropzone from "../../../components/vui-single-dropzone";
import VuiTextInput from "../../../components/vui-text-input";
import VuiActionForm from "../../../components/vui-action-form";
import useFormProduct from "../hooks/useFormProduct";
import { TableColumn } from "../../../components/vui-table/interface";
import VuiTable from "../../../components/vui-table";
import VuiSelect from "../../../components/vui-select";
import EditVariantPriceModal from "../components/edit-variant-price-modal";
import { ProductVariant } from "../../../entities/product/product-variant";
import useGetSelectOption from "../../../hooks/useGetSelectOption";
import { validationSchema } from "./validation";
import { Media } from "../../../entities/media";
import {useIsAdmin} from "../../../context/auth";
import UpdateVariantStatusSwitch from "../components/update-variant-status";

const ProductFormModule = (props: ProductFormModuleProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();

  const { categoryData, loadCategoryData } = useGetSelectOption();
  const loadCategoryDataRef = useRef(loadCategoryData);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const { data, getData, isFetchingData, submitLoading, submitData } = useFormProduct(t);
  const getDataRef = useRef(getData);
  const [selectedData, setSelectedData] = useState<
    ProductVariant | undefined
  >();
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleCloseEditModal = useCallback(() => {
    setOpenEditModal(false);
  }, []);

  const handleClickEdit = useCallback((values: ProductVariant) => {
    setSelectedData(values);
    setOpenEditModal(true);
  }, []);

  const handleConfirmEditPrice = useCallback(() => {
    setSelectedData(undefined);
    handleCloseEditModal();
    if (id) {
      getDataRef.current(Number(id));
    }
  }, [handleCloseEditModal, id]);

  const variantColumns: TableColumn[] = [
    {
      key: "provider_product_id",
      dataIndex: "provider_product_id",
      title: t("text.productCode"),
    },
    { key: "name", dataIndex: "name", title: t("text.name") },
    {
      key: "base_price",
      dataIndex: "base_price",
      title: isAdmin ? t("text.basePrice") : t("text.plazatopupPrice"),
      type: "currency",
    },
    {
      key: "sell_price",
      dataIndex: "sell_price",
      title: t("text.sellPrice"),
      type: "currency",
    },
    {
      key: "action",
      dataIndex: "id",
      title: "",
      render: (value, values) => (
        <Group position="right" spacing="xs">
          <Button
            variant="outline"
            size="xs"
            onClick={() => handleClickEdit(values)}
          >
            {t("button.editPrice")}
          </Button>
        </Group>
      ),
    },
  ];

  if (!isAdmin) {
    variantColumns.splice(4, 0, {
      key: "is_active",
      dataIndex: "id",
      title: t("text.status"),
      render: (value, values) => {
        return (
            <UpdateVariantStatusSwitch id={values.id} is_active={values.is_active}/>
        )
      }
    })
  } else {
    variantColumns.splice(4, 0, {
      key: "provider",
      dataIndex: "provider",
      title: t("text.provider"),
      render: (value, values) => {
        return (
            <Text transform="uppercase">{value}</Text>
        )
      }
    })
  }

  const serverColumns: TableColumn[] = [
    { key: "provider_server_id", dataIndex: "provider_server_id", title: t("text.id") },
    { key: "name", dataIndex: "name", title: t("text.name") },
  ];

  const form = useForm<ProductFormValues>({
    initialValues: {
      photo_id: null,
      unit: "",
      category_id: null,
      photo: null,
      name: "",
      servers: [],
      variants: [],
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    (values: ProductFormValues) => {
      const normalizeValue = {
        id: id,
        photo_id:
          typeof values.photo_id === "number"
            ? values.photo_id
            : values.photo_id?.id,
        unit: values.unit,
      };

      submitData(normalizeValue, form.setFieldError);
    },
    [id, form, submitData]
  );

  const handleChangeBannerImage = useCallback(
    (value: Media | null) => {
      form.setFieldValue("photo_id", value ? value.id : value);
    },
    [form]
  );

  useMemo(() => {
    (async () => {
      if (!hasInitialized) {
        await loadCategoryDataRef.current();

        setHasInitialized(true);
      }

      if (id) {
        await getDataRef.current(Number(id));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setValues({
        ...data,
        photo_id: data.photo,
        unit: data.unit || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {selectedData && (
        <EditVariantPriceModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          onConfirm={handleConfirmEditPrice}
          data={selectedData}
        />
      )}

      <VuiHeaderPage
        title={t("pages.product.detail.title")}
        backUrl={"/product"}
      />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <VuiTextInput
              label={t("form.name.label")}
              placeholder={t("form.name.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              {...form.getInputProps("name")}
            />

            <VuiTextInput
              label={t("form.unit.label")}
              placeholder={t("form.unit.placeholder")}
              skeletonLoading={isFetchingData}
              disabled={!isAdmin}
              {...form.getInputProps("unit")}
            />

            <VuiSelect
              label={t("form.category.label")}
              placeholder={t("form.category.placeholder")}
              disabled
              data={categoryData}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("category_id")}
            />

            <VuiSingleDropzone
              label={t("form.image.label")}
              skeletonLoading={isFetchingData}
              onChangeImage={(value) => handleChangeBannerImage(value)}
              onRemoveImage={() => handleChangeBannerImage(null)}
              {...form.getInputProps("photo_id")}
            />

            <VuiTable
              dataSource={form.values.variants}
              columns={variantColumns}
              label={t("form.variants.label")}
              withPagination={false}
            />

            <VuiTable
              dataSource={form.values.servers}
              columns={serverColumns}
              label={t("form.servers.label")}
              withPagination={false}
            />
          </Stack>

          <VuiActionForm backUrl="/product" submitBtnLoading={submitLoading} />
        </form>
      </Paper>
    </>
  );
};

export default ProductFormModule;
