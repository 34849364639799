import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  shadows: {
    lg:
      "0px 6px 10px 0px hsla(0,0%,0%,0.14), \n" +
      "\t\t0px 1px 18px 0px hsla(0,0%,0%,0.12), \n" +
      "\t\t0px 3px 5px -1px hsla(0,0%,0%,0.2)",
  },
  colors: {
    plazatopup: ['#5451DB', '#5451DB', '#5451DB', '#5451DB', '#5451DB', '#5451DB', '#5451DB', '#5451DB', '#5451DB','#5451DB' ],
  },
  primaryColor: "violet",
  components: {
    Paper: {
      defaultProps: {
        shadow: "lg",
      },
      styles: (theme) => ({
        root: {
          border:
            theme.colorScheme === "dark"
              ? "1px solid #373A40"
              : "1px solid #ced4da",
        },
      }),
    },
    NumberInput: {
      defaultProps: {
        size: "md",
      },
    },
    DateRangePicker: {
      defaultProps: {
        size: "md",
      },
    },
    TextInput: {
      defaultProps: {
        size: "md",
      },
    },
    MultiSelect: {
      defaultProps: {
        size: "md",
      },
    },
    Select: {
      defaultProps: {
        size: "md",
      },
    },
    DatePicker: {
      defaultProps: {
        size: "md",
      },
    },
    PasswordInput: {
      defaultProps: {
        size: "md",
      },
    },
    Skeleton: {
      defaultProps: {
        radius: "sm",
      },
    },
  },
};
