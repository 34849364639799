import { RouteObject } from "react-router-dom";
import NotFoundPage from "../pages/not-found";
import appRoutes from "./app";
import authRoutes from "./auth";

const routes: RouteObject[] = [
  ...appRoutes,
  ...authRoutes,
  { path: "*", element: <NotFoundPage /> },
];

export default routes;
