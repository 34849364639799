import { useCallback, useMemo, useRef, useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarMenuItemProps } from "./interface";
import { useStyles } from "./style";

export function SidebarMenuItem({
  url,
  icon: Icon,
  label,
  initiallyOpened,
  children,
}: SidebarMenuItemProps) {
  const { classes, theme, cx } = useStyles();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const { pathname } = useLocation();
  const hasChildren = Array.isArray(children);
  const [opened, setOpened] = useState<boolean>(initiallyOpened || false);
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  const itemLinks = useMemo(() => {
    const items = hasChildren ? children : [];

    return items?.map((link) => (
      <Text
        component={Link}
        className={cx(classes.link, {
          [classes.linkActive]: link.url === pathname,
        })}
        to={link.url}
        key={link.label}
      >
        {link.label}
      </Text>
    ));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChildren, children]);

  const handleClickButton = useCallback(() => {
    if (hasChildren) {
      setOpened((open) => !open);
    } else {
      navigateRef.current(url);
    }
  }, [url, hasChildren]);

  return (
    <>
      <UnstyledButton
        onClick={handleClickButton}
        className={cx(classes.control, {
          [classes.controlActive]: pathname === url && !hasChildren,
        })}
      >
        <Group position="apart" spacing={0}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ThemeIcon variant="light" size={30}>
              <Icon size={18} />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasChildren && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              stroke={1.5}
              style={{
                transform: opened
                  ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                  : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasChildren ? <Collapse in={opened}>{itemLinks}</Collapse> : null}
    </>
  );
}
