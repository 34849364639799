import * as Yup from "yup";
import { TFunction } from "react-i18next";
import {EnumPromoDiscountType} from "../../../entities/promo";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    code: Yup.string().required().label(t("form.code.label")),
    name: Yup.string().required().label(t("form.name.label")),
    max_used: Yup.number().min(1).required().label(t("form.maximumUsed.label")),

    percentage: Yup
        .number()
        .when("discount_type_id", {
          is: EnumPromoDiscountType.PERCENTAGE,
          then: Yup.number().required().label(t("form.percentage.label"))
        }),
    max_discount: Yup
        .number()
        .when("discount_type_id", {
          is: EnumPromoDiscountType.PERCENTAGE,
          then: Yup.number().required().label(t("form.maximumDiscount.label"))
        }),
    period: Yup.mixed().required().label(t("form.period.label")),
    type_id: Yup.mixed().required().label(t("form.type.label")),
    discount_type_id: Yup.mixed()
      .required()
      .label(t("form.discountType.label")),
    discount_amount: Yup
        .number()
        .when("discount_type_id", {
          is: EnumPromoDiscountType.AMOUNT,
          then: Yup.number().required().label(t("form.discountAmount.label"))
        }),
    promo_item_ids: Yup.mixed().required().label(t("form.product.label")),
  };

  return Yup.object().shape(shapes);
};
