import { useMemo, useState } from "react";
import {
  Group,
  Navbar,
  ScrollArea,
  SegmentedControl,
  Text,
} from "@mantine/core";
import { IconLogout } from "@tabler/icons";
import { SidebarMenuItem } from "../sidebar-menu-item";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { generateSidebarMenu, getSidebarSectionFromUrl, filterSidebarSection } from "./function";
import { SidebarProps, SidebarSection } from "./interface";
import { SidebarMenuItemProps } from "../sidebar-menu-item/interface";
import ColorSchemeToggle from "../color-scheme-toggle";
import { Link, useLocation } from "react-router-dom";
import {useAuth, useIsAdmin} from "../../../../context/auth";



const Sidebar = (props: SidebarProps) => {
  const { opened, logout } = props;
  const { pathname } = useLocation();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const sidebarMenus: SidebarMenuItemProps[] = generateSidebarMenu(t);
  const [section, setSection] = useState<SidebarSection>(
    getSidebarSectionFromUrl(sidebarMenus, pathname)
  );
  const { user } = useAuth();
  const isAdmin = useIsAdmin();

  const renderMenus = useMemo(() => {
    const menuItem = filterSidebarSection(sidebarMenus, section, user?.role_user.role.name);

    return (
      <Navbar.Section grow mt="md" component={ScrollArea}>
        {menuItem.map((item, index) => (
          <SidebarMenuItem {...item} key={`${index}-${item.label}`} />
        ))}
      </Navbar.Section>
    );
  }, [section, sidebarMenus, user]);

  return (
    <Navbar
      width={{ sm: 300 }}
      p="md"
      hiddenBreakpoint="sm"
      className={classes.navbar}
      hidden={!opened}
    >
      <Navbar.Section className={classes.header}>
        <Group position="apart" className={classes.headerGroup}>
          <Link to={"/"}>
            {
              isAdmin ? (
                  <img width={140} src="/assets/images/logo.png" alt="Logo" />
              ) : user?.merchant_name
            }
          </Link>
          <ColorSchemeToggle />
        </Group>
      </Navbar.Section>

      <Navbar.Section>
        <SegmentedControl
          value={section}
          onChange={(value: "account" | "system") => setSection(value)}
          transitionDuration={400}
          transitionTimingFunction="linear"
          fullWidth
          data={[
            { label: t("sidebar.section.system"), value: "system" },
            { label: t("sidebar.section.account"), value: "account" },
          ]}
        />
      </Navbar.Section>

      {renderMenus}

      <Navbar.Section className={classes.footer}>
        <Text onClick={logout} className={classes.link}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>{t("text.logout")}</span>
        </Text>
      </Navbar.Section>
    </Navbar>
  );
};

export default Sidebar;
