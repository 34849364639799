import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    old_password: Yup.string()
      .min(
        6,
        t("validation.minLength", { item: t("form.oldPassword.label"), min: 6 })
      )
      .required()
      .label(t("form.oldPassword.label")),
    new_password: Yup.string()
      .min(
        6,
        t("validation.minLength", { item: t("form.newPassword.label"), min: 6 })
      )
      .required()
      .label(t("form.newPassword.label")),
    new_password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref("new_password"), null],
        t("validation.match", { item: t("form.newPasswordConfirmation.label") })
      )
      .min(
        6,
        t("validation.minLength", {
          item: t("form.newPasswordConfirmation.label"),
          min: 6,
        })
      )
      .required()
      .label(t("form.newPasswordConfirmation.label")),
  };

  return Yup.object().shape(shapes);
};
