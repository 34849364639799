import {Photo} from "./photo";
import {Product} from "./product/product";

export enum EnumMerchantFeeType {
    PERCENTAGE = "10",
    AMOUNT = "11",
}

export type MerchantFeeType =
    | EnumMerchantFeeType.PERCENTAGE
    | EnumMerchantFeeType.AMOUNT;

export type Merchant = {
    id: number;
    name: string;
    fee_amount: number;
    max_fee: number;
    fee_type_id: MerchantFeeType;
    percentage: number;
    photo_id: number
    photo: Photo;
    products: Product[]
};