import { Button, Group } from "@mantine/core";
import { Link } from "react-router-dom";
import { VuiActionTableProps } from "./interface";
import { useTranslation } from "react-i18next";

const VuiActionTable = (props: VuiActionTableProps) => {
  const { t } = useTranslation();
  const {
    deleteBtnLabel = t("button.delete"),
    detailBtnLabel = t("button.detail"),
    detailUrl = "",
    onDelete,
    canDelete = false,
  } = props;

  return (
    <Group position="right" spacing="xs">
      {detailUrl && (
        <Button variant="outline" to={detailUrl} size="xs" component={Link}>
          {detailBtnLabel}
        </Button>
      )}

      {canDelete && (
        <Button onClick={onDelete} color="red" size="xs" variant="outline">
          {deleteBtnLabel}
        </Button>
      )}
    </Group>
  );
};

export default VuiActionTable;
