import { useCallback, useState } from "react";
import { BaseQueryParams } from "../../../entities/query-params";
import { TFunction } from "react-i18next";
import MerchantPromoRepository from "../../../repositories/merchant/promo-repository";
import { Promo } from "../../../entities/promo";
import {useIsAdmin} from "../../../context/auth";
import AdminPromoRepository from "../../../repositories/admin/promo-repository";
import {KeyString} from "../../../entities/repository";

const useGetPromo = (t: TFunction) => {
    const isAdmin = useIsAdmin()
  const [dataSource, setDataSource] = useState<Promo[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(
    (params: KeyString | BaseQueryParams) => {
      setLoading(true);

        (isAdmin ? AdminPromoRepository : MerchantPromoRepository).findAll(params)
        .then((response) => {
          const { data: responseData, meta } = response.data;
          setDataSource(responseData);
          setTotal(meta.last_page);
        })
        .catch((err) => {
          const errMessage =
            err?.response?.message || t("notification.error.default");
          setError(errMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [t, isAdmin]
  );

  return {
    dataSource,
    total,
    error,
    loading,
    loadData,
  };
};

export default useGetPromo;
