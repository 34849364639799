import { Button, Group, MediaQuery, SelectItem } from "@mantine/core";
import VuiTable from "../../components/vui-table";
import { TableColumn } from "../../components/vui-table/interface";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VuiSearch from "../../components/vui-search";
import { useDebounce } from "usehooks-ts";
import { DEFAULT_PER_PAGE } from "../../constant";
import { Link } from "react-router-dom";
import VuiHeaderPage from "../../components/vui-header-page";
import useGetOrder from "./hooks/useGetOrder";
import VuiSelect from "../../components/vui-select";
import { BaseSort, OrderDirection } from "../../entities/query-params";
import useGetSelectOption from "../../hooks/useGetSelectOption";
import { backendDate, frontendDate, subtractDays } from "../../helpers";
import VuiDateRangePicker from "../../components/vui-date-range-picker";
import { DateRangePickerValue } from "@mantine/dates";
import {useIsAdmin} from "../../context/auth";

const OrderModule = () => {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();
  const { dataSource, loadData, loading, total } = useGetOrder(t);
  const loadDataRef = useRef(loadData);

  const {
    orderStatusData,
    loadOrderStatusData,
    paymentMethodData,
    loadPaymentMethodData,
    productData,
    loadProductData,
    merchantData,
    loadMerchantData
  } = useGetSelectOption();

  const loadOrderStatusDataRef = useRef(loadOrderStatusData);
  const loadPaymentMethodDataRef = useRef(loadPaymentMethodData);
  const loadProductDataRef = useRef(loadProductData);
  const loadMerchantDataRef = useRef(loadMerchantData);

  const [selectedOrderStatus, setSelectedOrderStatus] = useState<string | null>(
    null
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateRangePickerValue>([
    subtractDays(7),
    new Date(),
  ]);
  const [selectedMerchant, setSelectedMerchant] = useState<string | null>(
      null
  );

  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchValue, 250);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const [sort, setSort] = useState<BaseSort>({
    order_by: "date",
    sorted_by: "desc",
  });

  const sortOptions: SelectItem[] = [
    {
      value: "date-asc",
      label: t("select.sortAsc", { item: t("text.date") }),
    },
    {
      value: "date-desc",
      label: t("select.sortDesc", { item: t("text.date") }),
    },
    {
      value: "customer_email-asc",
      label: t("select.sortAsc", { item: t("text.merchant") }),
    },
    {
      value: "customer_email-desc",
      label: t("select.sortDesc", { item: t("text.merchant") }),
    },
  ];

  const columns: TableColumn[] = [
    {
      key: "customer_email",
      dataIndex: "customer_email",
      title: t("text.customer"),
    },
    {
      key: "product_name",
      dataIndex: "product_name",
      title: t("text.product"),
    },
    {
      key: "variant_name",
      dataIndex: "variant_name",
      title: t("text.variant"),
    },
    {
      key: "payment_name",
      dataIndex: "payment_name",
      title: t("text.paymentMethod"),
    },
    {
      key: "status_name",
      dataIndex: "status_name",
      title: t("text.status"),
    },
    {
      key: "date",
      dataIndex: "date",
      title: t("text.date"),
      render: (value) => <>{frontendDate(value)}</>,
    },
    {
      key: "grand_total",
      dataIndex: "grand_total",
      type: "currency",
      title: t("text.price"),
    },
    {
      key: "action",
      dataIndex: "id",
      title: "",
      render: (value) => (
        <Group position="right" spacing="xs">
          <Button
            variant="outline"
            to={`/order/${value}`}
            size="xs"
            component={Link}
          >
            {t("button.detail")}
          </Button>
        </Group>
      ),
    },
  ];

  if (isAdmin) {
    columns.splice(7, 0, {
      key: "merchant_name", dataIndex: "merchant_name", title: t("text.merchant")
    })
  }

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        status: selectedOrderStatus,
        product: selectedProduct,
        payment_method: selectedPaymentMethod,
        date_from: backendDate(selectedDate[0]),
        date_to: backendDate(selectedDate[1]),
        merchant: selectedMerchant
      });
    },
    [
      sort,
      debouncedValue,
      selectedProduct,
      selectedOrderStatus,
      selectedPaymentMethod,
      selectedDate,
      selectedMerchant
    ]
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleChangeOrderStatus = useCallback(
    (value: string | null) => {
      setSelectedOrderStatus(value);
      setPage(1);

      loadDataRef.current({
        page: 1,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        status: value,
        payment_method: selectedPaymentMethod,
        product: selectedProduct,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        date_from: backendDate(selectedDate[0]),
        date_to: backendDate(selectedDate[1]),
        merchant: selectedMerchant
      });
    },
    [sort, debouncedValue, selectedDate, selectedPaymentMethod, selectedProduct, selectedMerchant]
  );

  const handleChangePaymentMethod = useCallback(
    (value: string | null) => {
      setSelectedPaymentMethod(value);
      setPage(1);

      loadDataRef.current({
        page: 1,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        status: selectedOrderStatus,
        payment_method: value,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        product: selectedProduct,
        date_from: backendDate(selectedDate[0]),
        date_to: backendDate(selectedDate[1]),
        merchant: selectedMerchant
      });
    },
    [sort, debouncedValue, selectedDate, selectedProduct, selectedOrderStatus, selectedMerchant]
  );

  const handleChangeProduct = useCallback(
    (value: string | null) => {
      setSelectedProduct(value);
      setPage(1);

      loadDataRef.current({
        page: 1,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        status: selectedOrderStatus,
        payment_method: selectedPaymentMethod,
        product: value,
        date_from: backendDate(selectedDate[0]),
        date_to: backendDate(selectedDate[1]),
        merchant: selectedMerchant
      });
    },
    [
      sort,
      debouncedValue,
      selectedDate,
      selectedOrderStatus,
      selectedPaymentMethod,
      selectedMerchant
    ]
  );

  const handleChangeMerchant = useCallback(
      (value: string | null) => {
        setSelectedMerchant(value);
        setPage(1);

        loadDataRef.current({
          page: page,
          per_page: DEFAULT_PER_PAGE,
          search: debouncedValue,
          sorted_by: sort.sorted_by,
          order_by: sort.order_by,
          status: selectedOrderStatus,
          payment_method: selectedPaymentMethod,
          product: selectedProduct,
          date_from: backendDate(selectedDate[0]),
          date_to: backendDate(selectedDate[1]),
          merchant: value
        });
      },
      [
          page,
          sort,
          debouncedValue,
          selectedProduct,
          selectedOrderStatus,
          selectedPaymentMethod,
          selectedDate
      ]
  );

  const handleChangeDate = useCallback(
    (value: DateRangePickerValue) => {
      setSelectedDate(value);
      setPage(1);

      loadDataRef.current({
        page: 1,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        status: selectedOrderStatus,
        payment_method: selectedPaymentMethod,
        product: selectedProduct,
        date_from: backendDate(value[0]),
        date_to: backendDate(value[1]),
        merchant: selectedMerchant
      });
    },
    [
      sort,
      debouncedValue,
      selectedProduct,
      selectedOrderStatus,
      selectedPaymentMethod,
      selectedMerchant
    ]
  );

  const handleChangeSort = useCallback(
    (value: string) => {
      const splitValue = value.split("-");
      setSort({
        order_by: splitValue[0],
        sorted_by: splitValue[1] as OrderDirection,
      });

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: splitValue[1] as OrderDirection,
        order_by: splitValue[0],
        status: selectedOrderStatus,
        payment_method: selectedPaymentMethod,
        product: selectedProduct,
        date_from: backendDate(selectedDate[0]),
        date_to: backendDate(selectedDate[1]),
        merchant: selectedMerchant
      });
    },
    [
      page,
      selectedProduct,
      selectedOrderStatus,
      selectedPaymentMethod,
      debouncedValue,
      selectedDate,
      selectedMerchant
    ]
  );

  useEffect(() => {
    if (!hasInitialized) {
      loadOrderStatusDataRef.current();
      loadPaymentMethodDataRef.current();
      loadProductDataRef.current();
      loadMerchantDataRef.current();

      setHasInitialized(true);
    }

    loadDataRef.current({
      page: 1,
      per_page: DEFAULT_PER_PAGE,
      search: debouncedValue,
      sorted_by: sort.sorted_by,
      order_by: sort.order_by,
      status: selectedOrderStatus,
      payment_method: selectedPaymentMethod,
      product: selectedProduct,
      date_from: backendDate(selectedDate[0]),
      date_to: backendDate(selectedDate[1]),
      merchant: selectedMerchant
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <>
      <VuiHeaderPage title={t("pages.order.title")} />

      <Group position="apart" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSearch value={searchValue} onChange={handleSearch} />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.sort")}
            onChange={handleChangeSort}
            data={sortOptions}
          />
        </MediaQuery>
      </Group>

      <Group position="left" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiDateRangePicker
            placeholder={t("select.date")}
            value={selectedDate}
            onChange={handleChangeDate}
            fullWidth={false}
          />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.status")}
            onChange={handleChangeOrderStatus}
            data={orderStatusData}
          />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.paymentMethod")}
            onChange={handleChangePaymentMethod}
            data={paymentMethodData}
          />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.product")}
            onChange={handleChangeProduct}
            data={productData}
          />
        </MediaQuery>

        {
          isAdmin ? (
              <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                <VuiSelect
                    placeholder={t("select.merchant")}
                    onChange={handleChangeMerchant}
                    data={merchantData}
                />
              </MediaQuery>
          ) : ''
        }
      </Group>



      <VuiTable
        totalPage={total}
        onChangePage={handleChangePage}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        page={page}
      />
    </>
  );
};

export default OrderModule;
