import React, { useCallback } from "react";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { theme } from "./styles/mantine";
import GlobalStyles from "./styles/global";
import { NotificationsProvider } from "@mantine/notifications";
import { useColorScheme, useHotkeys, useLocalStorage } from "@mantine/hooks";

const App = () => {
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: preferredColorScheme,
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = useCallback(
    (value?: ColorScheme) => {
      const color = value || (colorScheme === "dark" ? "light" : "dark");
      setColorScheme(color);
    },
    [colorScheme, setColorScheme]
  );

  useHotkeys([["mod+Q", () => toggleColorScheme()]]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          ...theme,
          colorScheme,
        }}
      >
        <NotificationsProvider>
          <GlobalStyles />
          <>{useRoutes(routes)}</>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
