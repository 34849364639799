import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../../components/vui-helmet";
import HomeModule from "../../../../modules/page/home";

const AppHomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.home.metaTitle")} />

      <HomeModule />
    </>
  );
};

export default AppHomePage;
