import { useCallback, useState } from "react";
import { TermsConditionFormValues } from "../interface";
import { AxiosError } from "axios";
import PageRepository from "../../../../repositories/page-repository";
import { handleLoadDataErrorResponse } from "../../../../helpers";
import { TFunction } from "react-i18next";

const useFormTermsCondition = (t: TFunction) => {
  const [data, setData] = useState<TermsConditionFormValues>();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);

  const getData = (id: number) => {
    setIsFetchingData(true);

    PageRepository.findOne(id)
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData);
      })
      .catch(() => {
        setIsErrorOnFetching(true);
        handleLoadDataErrorResponse(t, t("text.termsCondition"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const submitData = useCallback((values: TermsConditionFormValues) => {
    setSubmitLoading(true);

    return new Promise((resolve, reject) => {
      PageRepository.termsCondition(values)
        .then(() => {
          resolve(true);
        })
        .catch((error: AxiosError) => {
          reject(error);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    });
  }, []);

  return {
    submitData,
    submitLoading,
    getData,
    isFetchingData,
    data,
    isErrorOnFetching,
  };
};

export default useFormTermsCondition;
