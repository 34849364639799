import { Box } from "@mantine/core";
import ColorSchemeToggle from "./components/color-scheme-toggle";
import { styColorSchemeBox, styOutletContainer } from "./style";
import { useAuth } from "../../context/auth";
import VuiScreenLoader from "../../components/vui-screen-loader";
import { Navigate, Outlet } from "react-router-dom";
import _ from "lodash";
import usePassportService from "../../hooks/usePassportService";

const GuestLayout = () => {
  const { user } = useAuth();
  const { isOnFetchingUser } = usePassportService();

  if (isOnFetchingUser) {
    return <VuiScreenLoader />;
  }

  if (!_.isEmpty(user) && !isOnFetchingUser) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <Box>
      <Box sx={styOutletContainer}>
        <Outlet />
      </Box>

      <Box sx={styColorSchemeBox}>
        <ColorSchemeToggle />
      </Box>
    </Box>
  );
};

export default GuestLayout;
