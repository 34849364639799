import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/vui-helmet";
import SettingModule from "../../../modules/setting";

const AppSettingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.setting.metaTitle")} />

      <SettingModule />
    </>
  );
};

export default AppSettingPage;
