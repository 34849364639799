import { useState } from "react";
import { BaseQueryParams } from "../../../entities/query-params";
import { TFunction } from "react-i18next";
import { KeyString } from "../../../entities/repository";
import { Order } from "../../../entities/order/order";
import MerchantOrderRepository from "../../../repositories/merchant/order-repository";
import {useIsAdmin} from "../../../context/auth";
import AdminOrderRepository from "../../../repositories/admin/order-repository";

const useGetOrder = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [dataSource, setDataSource] = useState<Order[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = (params: KeyString | BaseQueryParams) => {
    setLoading(true);

    (isAdmin ? AdminOrderRepository : MerchantOrderRepository).findAll(params)
      .then((response) => {
        const { data: responseData, meta } = response.data;
        setDataSource(responseData);
        setTotal(meta.last_page);
      })
      .catch((err) => {
        const errMessage =
          err?.response?.message || t("notification.error.default");
        setError(errMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    dataSource,
    total,
    error,
    loading,
    loadData,
  };
};

export default useGetOrder;
