import { ActionIcon, Button, Group, Paper, Stack, Text } from "@mantine/core";
import { HomeBanner, HomeFormValues, HomeWhy } from "./interface";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useFormHome from "./hooks/useFormHome";
import {
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../helpers";
import { AxiosError } from "axios";
import { validationSchema } from "./validation";
import VuiTextInput from "../../../components/vui-text-input";
import VuiActionForm from "../../../components/vui-action-form";
import { HOME_PAGE_ID } from "../../../constant";
import VuiSwitch from "../../../components/vui-switch";
import VuiSingleDropzone from "../../../components/vui-single-dropzone";
import { Media } from "../../../entities/media";
import { IconPlus, IconTrash } from "@tabler/icons";
import VuiTextarea from "../../../components/vui-textarea";

const bannerDefaultValue: HomeBanner = {
  can_click: false,
  image: null,
  link: "",
};

const whyDefaultValue: HomeWhy = {
  title: "",
  description: "",
  icon: null,
};

const HomeModule = () => {
  const { t } = useTranslation();
  const { submitLoading, submitData, data, getData, isFetchingData } =
    useFormHome(t);
  const getDataRef = useRef(getData);
  const form = useForm<HomeFormValues>({
    initialValues: {
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      banners: [bannerDefaultValue],
      whys: [whyDefaultValue],
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    (values: HomeFormValues) => {
      submitData(values)
        .then(() => {
          handleSaveSuccessResponse(t, t("text.home"), true);
        })
        .catch((error: AxiosError) => {
          handleSaveErrorResponse(t, error, form.setFieldError);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitData, t]
  );

  const handleChangeBannerImage = useCallback(
    (index: number, value: Media | null) => {
      form.setFieldValue(`banners.${index}.image`, value ? value.url : value);
    },
    [form]
  );

  const handleAddBanner = useCallback(() => {
    form.insertListItem("banners", bannerDefaultValue);
  }, [form]);

  const handleDeleteBanner = useCallback(
    (index: number) => {
      form.removeListItem("banners", index);
    },
    [form]
  );

  const bannerFields = useMemo(() => {
    return form.values.banners.map((banner, index) => (
      <Paper shadow="xs" radius="md" py="sm" px="md" key={`${index}_banner`}>
        <Group position="apart">
          <Text size="lg" sx={{ fontWeight: 500 }}>{`${t(
            "form.banner.label"
          )} ${index + 1}`}</Text>

          <ActionIcon onClick={() => handleDeleteBanner(index)}>
            <IconTrash size={24} />
          </ActionIcon>
        </Group>

        <Stack>
          <VuiSingleDropzone
            label={t("form.image.label")}
            onChangeImage={(value) => handleChangeBannerImage(index, value)}
            onRemoveImage={() => handleChangeBannerImage(index, null)}
            height={100}
            width={180}
            {...form.getInputProps(`banners.${index}.image`)}
          />

          <VuiSwitch
            label={t("form.hasLink.label")}
            {...form.getInputProps(`banners.${index}.can_click`)}
          />

          {form.values.banners[index].can_click && (
            <VuiTextInput
              label={t("form.link.label")}
              placeholder={t("form.link.placeholder")}
              {...form.getInputProps(`banners.${index}.link`)}
            />
          )}
        </Stack>
      </Paper>
    ));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.banners, t, handleChangeBannerImage, handleDeleteBanner]);

  const handleChangeWhyImage = useCallback(
    (index: number, value: Media | null) => {
      form.setFieldValue(`whys.${index}.icon`, value ? value.url : value);
    },
    [form]
  );

  const handleAddWhy = useCallback(() => {
    form.insertListItem("whys", whyDefaultValue);
  }, [form]);

  const handleDeleteWhy = useCallback(
    (index: number) => {
      form.removeListItem("whys", index);
    },
    [form]
  );

  const whyFields = useMemo(() => {
    return form.values.whys.map((why, index) => (
      <Paper shadow="xs" radius="md" py="sm" px="md" key={`${index}_banner`}>
        <Group position="apart">
          <Text size="lg" sx={{ fontWeight: 500 }}>{`${t("form.why.label")} ${
            index + 1
          }`}</Text>

          <ActionIcon onClick={() => handleDeleteWhy(index)}>
            <IconTrash size={24} />
          </ActionIcon>
        </Group>

        <Stack>
          <VuiSingleDropzone
            label={t("form.icon.label")}
            onChangeImage={(value) => handleChangeWhyImage(index, value)}
            onRemoveImage={() => handleChangeWhyImage(index, null)}
            width={100}
            height={60}
            {...form.getInputProps(`whys.${index}.icon`)}
          />

          <VuiTextInput
            label={t("form.title.label")}
            placeholder={t("form.title.placeholder")}
            {...form.getInputProps(`whys.${index}.title`)}
          />

          <VuiTextarea
            label={t("form.description.label")}
            placeholder={t("form.description.placeholder")}
            {...form.getInputProps(`whys.${index}.description`)}
          />
        </Stack>
      </Paper>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.whys, handleChangeWhyImage, t, handleDeleteWhy]);

  useMemo(() => {
    getDataRef.current(HOME_PAGE_ID);
  }, []);

  useEffect(() => {
    if (data) {
      form.setValues(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage title={t("pages.home.title")} backUrl="/page" />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <VuiTextInput
              placeholder={t("form.metaTitle.placeholder")}
              label={t("form.metaTitle.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("meta_title")}
            />

            <VuiTextInput
              placeholder={t("form.metaDescription.placeholder")}
              label={t("form.metaDescription.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("meta_description")}
            />

            <VuiTextInput
              placeholder={t("form.metaKeyword.placeholder")}
              label={t("form.metaKeyword.label")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("meta_keyword")}
            />

            {bannerFields}

            <Button
              color="teal"
              variant="outline"
              onClick={handleAddBanner}
              leftIcon={<IconPlus size={18} />}
            >
              {t("button.addBanner")}
            </Button>

            {whyFields}

            <Button
              color="teal"
              variant="outline"
              onClick={handleAddWhy}
              leftIcon={<IconPlus size={18} />}
            >
              {t("button.addWhy")}
            </Button>
          </Stack>

          <VuiActionForm backUrl="/page" submitBtnLoading={submitLoading} />
        </form>
      </Paper>
    </>
  );
};

export default HomeModule;
