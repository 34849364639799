import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ActionIcon, Box, Group, Image, Skeleton, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IconPhoto, IconTrash } from "@tabler/icons";
import { useStyles } from "./style";
import { VuiSingleDropzoneProps } from "./interface";
import { useTranslation } from "react-i18next";
import useMedia from "../../hooks/useMedia";
import { Media, MediaForm } from "../../entities/media";
import { AxiosError } from "axios";
import { handleSaveErrorResponse, pxToRem } from "../../helpers";
import VuiFormError from "../vui-form-error";

const VuiSingleDropzone = (props: VuiSingleDropzoneProps) => {
  const {
    label,
    onChangeImage,
    onRemoveImage,
    error,
    value,
    skeletonLoading = false,
    disabled = false,
    width = 200,
    height = 140,
  } = props;
  const { classes, cx } = useStyles();
  const { storeMedia } = useMedia();
  const storeMediaRef = useRef(storeMedia);
  const { t } = useTranslation();
  const openRef = useRef<any>(null);
  const [file, setFile] = useState<Media | undefined | string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const removeFile = useCallback(() => {
    onRemoveImage();
    setFile(undefined);
  }, [onRemoveImage]);

  const renderPreview = useMemo(() => {
    if (!file) {
      return;
    }

    return (
      <Box
        className={cx(classes.imageBox, {
          [classes.imageBoxDisabled]: disabled,
        })}
      >
        <Image
          src={typeof file === "string" ? file : file.url}
          radius="sm"
          fit="contain"
          width={pxToRem(width)}
          height={pxToRem(height + 28)}
        />

        <ActionIcon color="red" variant="transparent" onClick={removeFile}>
          <IconTrash size={24} />
        </ActionIcon>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, removeFile, t, disabled, width, height]);

  const handleChangeFile = useCallback(
    (files: File[]) => {
      setIsLoading(true);

      const formData: MediaForm = {
        files: files,
        path: "file",
        disk: "public",
      };

      storeMediaRef
        .current(formData)
        .then((response) => {
          onChangeImage(response as Media);
          setFile(response as Media);
        })
        .catch((error: AxiosError) => {
          handleSaveErrorResponse(t, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [onChangeImage, t]
  );

  useEffect(() => {
    if (value && !hasInitialized) {
      setFile(value);
      setHasInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Skeleton visible={skeletonLoading}>
      <Box>
        {label && <Text className={classes.label}>{label}</Text>}

        <Dropzone
          multiple={false}
          onDrop={(files: File[]) => {
            handleChangeFile(files);
          }}
          disabled={disabled}
          loading={isLoading}
          openRef={openRef}
          className={cx({
            [classes.hideDropzone]: file,
            [classes.disabled]: disabled,
          })}
          sx={{
            width: pxToRem(width),
          }}
        >
          <Group
            position="center"
            className={classes.innerGroup}
            sx={{
              minHeight: pxToRem(height),
            }}
          >
            <Dropzone.Idle>
              <IconPhoto
                color="gray"
                size={pxToRem(width / 4.5)}
                stroke={1.5}
              />
            </Dropzone.Idle>
          </Group>
        </Dropzone>

        {renderPreview}

        {error && <VuiFormError message={error} />}
      </Box>
    </Skeleton>
  );
};

export default VuiSingleDropzone;
