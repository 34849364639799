import { Button, Group, Modal, Stack } from "@mantine/core";
import {
  EditVariantPriceFormValues,
  EditVariantPriceModalProps,
} from "./interface";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useCallback } from "react";
import VuiCurrencyInput from "../../../../components/vui-currency-input";
import useFormVariant from "../../hooks/useFormVariant";
import {
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../../helpers";
import { AxiosError } from "axios";

const EditVariantPriceModal = (props: EditVariantPriceModalProps) => {
  const { t } = useTranslation();
  const { open = false, onClose, onConfirm, data } = props;
  const { submitLoading, submitData } = useFormVariant(t);

  const form = useForm<EditVariantPriceFormValues>({
    initialValues: {
      sell_price: data.sell_price,
    },
  });

  const handleSubmit = useCallback(
    (values: EditVariantPriceFormValues) => {
      submitData(data.id, values)
        .then(() => {
          handleSaveSuccessResponse(t, t("text.variant"), true);
          onConfirm();
        })
        .catch((error: AxiosError) => {
          handleSaveErrorResponse(t, error, form.setFieldError);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, onConfirm, t]
  );

  return (
    <Modal opened={open} onClose={onClose} title={data.name}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <VuiCurrencyInput
            label={t("form.sellPrice.label")}
            {...form.getInputProps("sell_price")}
          />

          <Group mt="sm" position="apart" grow>
            <Button variant="outline" color="gray" onClick={onClose}>
              {t("button.cancel")}
            </Button>
            <Button loading={submitLoading} type="submit">
              {t("button.saveChanges")}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditVariantPriceModal;
