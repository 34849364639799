import { useCallback, useState } from "react";
import { BaseQueryParams } from "../../../entities/query-params";
import { TFunction } from "react-i18next";
import { Article } from "../../../entities/article";
import ArticleRepository from "../../../repositories/article-repository";

type Params = BaseQueryParams & {};

const useGetArticle = (t: TFunction) => {
  const [dataSource, setDataSource] = useState<Article[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(
    (params: Params) => {
      setLoading(true);

      ArticleRepository.findAll(params)
        .then((response) => {
          const { data: responseData, meta } = response.data;
          setDataSource(responseData);
          setTotal(meta.last_page);
        })
        .catch((err) => {
          const errMessage =
            err?.response?.message || t("notification.error.default");
          setError(errMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [t]
  );

  return {
    dataSource,
    total,
    error,
    loading,
    loadData,
  };
};

export default useGetArticle;
