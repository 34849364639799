export enum EnumPromoType {
  PRODUCT = "1",
  PAYMENT_METHOD = "2",
}

export enum EnumPromoDiscountType {
  PERCENTAGE = "3",
  AMOUNT = "4",
}

export type PromoType = EnumPromoType.PRODUCT | EnumPromoType.PAYMENT_METHOD;
export type PromoDiscountType =
  | EnumPromoDiscountType.PERCENTAGE
  | EnumPromoDiscountType.AMOUNT;

export type Promo = {
  id?: number;
  merchant_id?: number;
  type_id: PromoType;
  name: string;
  code: string;
  date_from: string | Date;
  date_to: string | Date;
  max_used: number;
  discount_type_id: PromoDiscountType;
  percentage: number | null;
  max_discount: number | null;
  discount_amount: number;
  items: [];
  can_delete: boolean;

  // from create
  promo_item_ids?: [];
};
