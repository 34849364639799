import { Paper, Stack } from "@mantine/core";
import { CustomerFormModuleProps, CustomerFormValues } from "./interface";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useEffect, useMemo, useRef } from "react";
import VuiTextInput from "../../../components/vui-text-input";
import VuiActionForm from "../../../components/vui-action-form";
import useFormCustomer from "../hooks/useFormCustomer";
import VuiTable from "../../../components/vui-table";
import { TableColumn } from "../../../components/vui-table/interface";
import { frontendDate } from "../../../helpers";
import {useIsAdmin} from "../../../context/auth";

const CustomerFormModule = (props: CustomerFormModuleProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const isAdmin = useIsAdmin()

  const { data, getData, isFetchingData } = useFormCustomer(t);
  const getDataRef = useRef(getData);

  const form = useForm<CustomerFormValues>({
    initialValues: {
      email: "",
      merchant_name: '',
      order_histories: [],
    },
  });

  const orderHistoryColumns: TableColumn[] = [
    { key: "number", dataIndex: "number", title: t("text.number") },
    {
      key: "date",
      dataIndex: "date",
      title: t("text.date"),
      render: (value) => <>{frontendDate(value)}</>,
    },
    {
      key: "grand_total",
      dataIndex: "grand_total",
      type: "currency",
      title: t("text.total"),
    },
    {
      key: "product_name",
      dataIndex: "product_name",
      title: t("text.product"),
    },
    {
      key: "variant_name",
      dataIndex: "variant_name",
      title: t("text.variant"),
    },
    {
      key: "status_name",
      dataIndex: "status_name",
      title: t("text.status"),
    },
  ];

  useMemo(() => {
    if (id) {
      getDataRef.current(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setValues(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage
        title={t("pages.customer.detail.title")}
        backUrl={"/customer"}
      />

      <Paper shadow="xs" radius="md" p="xl">
        <form>
          <Stack>
            <VuiTextInput
              label={t("form.email.label")}
              placeholder={t("form.email.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              {...form.getInputProps("email")}
            />

            {
              isAdmin ? (
                  <VuiTextInput
                      label={t("form.merchant.label")}
                      placeholder={t("form.merchant.placeholder")}
                      skeletonLoading={isFetchingData}
                      disabled
                      {...form.getInputProps("merchant_name")}
                  />
              ) : ''
            }

            <VuiTable
              dataSource={form.values.order_histories}
              columns={orderHistoryColumns}
              label={t("form.orderHistory.label")}
              withPagination={false}
            />
          </Stack>

          <VuiActionForm backUrl="/customer" forSubmit={false} />
        </form>
      </Paper>
    </>
  );
};

export default CustomerFormModule;
