import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../../components/vui-helmet";
import PrivacyPolicyModule from "../../../../modules/page/privacy-policy";

const AppPrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.privacyPolicy.metaTitle")} />

      <PrivacyPolicyModule />
    </>
  );
};

export default AppPrivacyPolicyPage;
