import { Button, Group, Paper, PasswordInput, Stack } from "@mantine/core";
import { ChangePasswordFormValues } from "./interface";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import VuiHeaderPage from "../../components/vui-header-page";
import { useCallback } from "react";
import useFormChangePassword from "./hooks/useFormChangePassword";
import {
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../helpers";
import { AxiosError } from "axios";
import { validationSchema } from "./validation";

const ChangePasswordModule = () => {
  const { t } = useTranslation();
  const { submitLoading, submitData } = useFormChangePassword();
  const form = useForm<ChangePasswordFormValues>({
    initialValues: {
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    (values: ChangePasswordFormValues) => {
      submitData(values)
        .then(() => {
          handleSaveSuccessResponse(t, t("text.password"), true);
        })
        .catch((error: AxiosError) => {
          handleSaveErrorResponse(t, error, form.setFieldError);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitData, t]
  );

  return (
    <>
      <VuiHeaderPage title={t("pages.changePassword.title")} />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <PasswordInput
              label={t("form.oldPassword.label")}
              placeholder={t("form.oldPassword.placeholder")}
              {...form.getInputProps("old_password")}
            />

            <PasswordInput
              label={t("form.newPassword.label")}
              placeholder={t("form.newPassword.placeholder")}
              {...form.getInputProps("new_password")}
            />

            <PasswordInput
              label={t("form.newPasswordConfirmation.label")}
              placeholder={t("form.newPasswordConfirmation.placeholder")}
              {...form.getInputProps("new_password_confirmation")}
            />
          </Stack>

          <Group position="right" mt="md">
            <Button loading={submitLoading} type="submit">
              {t("button.submit")}
            </Button>
          </Group>
        </form>
      </Paper>
    </>
  );
};

export default ChangePasswordModule;
