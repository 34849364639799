import api from "../services/api";
import { createCancelTokenHandler } from "../services/cancel-token.service";
import { AxiosPromise } from "axios";
import { ICreateRepository } from "../entities/repository";
import { SettingFormValues } from "../modules/setting/interface";
import { Resource } from "../entities/resource";
import { Setting } from "../entities/setting";

export interface ISettingRepository
  extends ICreateRepository<Partial<SettingFormValues>, Setting> {
  getSetting(params: any): AxiosPromise<Resource<Setting>>;
}

const SettingRepository: ISettingRepository = {
  getSetting(params: any) {
    return api.get("/api/merchant/setting", {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getSetting.name
        ].handleRequestCancellation().token,
    });
  },
  create(payload) {
    return api.post(`/api/merchant/setting`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(SettingRepository);

export default SettingRepository;
