import { Box, NumberInput, Skeleton } from "@mantine/core";
import { VuiCurrencyInputProps } from "./interface";

const VuiCurrencyInput = (props: VuiCurrencyInputProps) => {
  const {
    icon = <Box>Rp</Box>,
    min = 0,
    stepHoldDelay = 500,
    stepHoldInterval = (t) => Math.max(1000 / t ** 2, 25),
    skeletonLoading = false,
    ...other
  } = props;

  return (
    <Skeleton visible={skeletonLoading}>
      <NumberInput
        {...other}
        min={min}
        icon={icon}
        stepHoldDelay={stepHoldDelay}
        stepHoldInterval={stepHoldInterval}
      />
    </Skeleton>
  );
};

export default VuiCurrencyInput;
