import api from "../../services/api";
import {
  IFindAllRepository,
  IFindOneRepository,
  ISelectRepository,
} from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { Order } from "../../entities/order/order";

interface IMerchantOrderRepository<T, R = T>
  extends IFindAllRepository<T, R>,
    IFindOneRepository<T, R>,
    ISelectRepository<T> {}

const MerchantOrderRepository: IMerchantOrderRepository<Order> = {
  findAll: function (params) {
    return api.get(`/api/merchant/order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/merchant/order/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params) {
    return api.get(`/api/merchant/select/order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(MerchantOrderRepository);

export default MerchantOrderRepository;
