import {
    UpdateVariantStatusProps
} from "../edit-variant-price-modal/interface";
import useFormVariant from "../../hooks/useFormVariant";
import {useTranslation} from "react-i18next";
import {BaseSyntheticEvent, useCallback, useState} from "react";
import VuiSwitch from "../../../../components/vui-switch";
import {handleSaveSuccessResponse} from "../../../../helpers";
import {AxiosError} from "axios";

const UpdateVariantStatusSwitch = (props: UpdateVariantStatusProps) => {
    const { t } = useTranslation();
    const { id, is_active } = props;
    const [checked, setChecked] = useState(Boolean(is_active));
    const { updateStatus, updateStatusLoading } = useFormVariant(t);

    const handleChange = useCallback((data: BaseSyntheticEvent) => {
        setChecked(data.currentTarget.checked)

        updateStatus(id, {
            is_active: Number(data.currentTarget.checked)
        })
            .then(() => {
                handleSaveSuccessResponse(t, t("text.status"), true);
            })
            .catch((error: AxiosError) => {

            });
    }, [id, t, updateStatus])

    return (
        <>
            <VuiSwitch
                skeletonLoading={updateStatusLoading}
                checked={Boolean(checked)}
                onChange={handleChange}
            />
        </>
    )
}

export default UpdateVariantStatusSwitch