import { useState } from "react";
import { handleLoadDataErrorResponse } from "../../../helpers";
import { TFunction } from "react-i18next";
import AdminCustomerRepository from "../../../repositories/admin/customer-repository";
import { Customer } from "../../../entities/customer/customer";
import {useIsAdmin} from "../../../context/auth";
import MerchantCustomerRepository from "../../../repositories/merchant/customer-repository";

const useFormCustomer = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [data, setData] = useState<Customer>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);

  const getData = (id: number) => {
    setIsFetchingData(true);

    (isAdmin ? AdminCustomerRepository : MerchantCustomerRepository).findOne(id)
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData);
      })
      .catch(() => {
        setIsErrorOnFetching(true);
        handleLoadDataErrorResponse(t, t("text.merchant"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  return {
    data,
    isFetchingData,
    isErrorOnFetching,
    getData,
  };
};

export default useFormCustomer;
