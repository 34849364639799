import HomeModule from "../../../modules/home";
import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";

const AppPageHomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.home.metaTitle")} />

      <HomeModule />
    </>
  );
};

export default AppPageHomePage;
