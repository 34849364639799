import { Skeleton, Textarea } from "@mantine/core";
import { VuiTextareaProps } from "./interface";

const VuiTextarea = (props: VuiTextareaProps) => {
  const { skeletonLoading = false, ...inputProps } = props;
  return (
    <Skeleton visible={skeletonLoading}>
      <Textarea {...inputProps} />
    </Skeleton>
  );
};

export default VuiTextarea;
