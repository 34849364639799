import { DateRangePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import { VuiDateRangePickerProps } from "./interface";
import { pxToRem } from "../../helpers";
import { Box, Skeleton } from "@mantine/core";

const VuiDateRangePicker = (props: VuiDateRangePickerProps) => {
  const {
    fullWidth = true,
    skeletonLoading = false,
    icon = <IconCalendar size={20} />,
    size = "md",
    inputFormat = "DD MMMM YYYY",
    ...other
  } = props;

  return (
    <Skeleton
      visible={skeletonLoading}
      sx={{
        width: fullWidth ? "100%" : pxToRem(320),
        "@media (max-width: 768px)": {
          width: "100%",
        },
      }}
    >
      <Box>
        <DateRangePicker
          {...other}
          size={size}
          inputFormat={inputFormat}
          sx={{
            maxWidth: fullWidth ? "100%" : pxToRem(320),
            "@media (max-width: 768px)": {
              maxWidth: "100%",
            },
          }}
          icon={icon}
        />
      </Box>
    </Skeleton>
  );
};

export default VuiDateRangePicker;
