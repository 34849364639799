import api from "../../services/api";
import { IUpdateRepository } from "../../entities/repository";
import { EditVariantPriceFormValues } from "../../modules/product/components/edit-variant-price-modal/interface";
import { ProductVariant } from "../../entities/product/product-variant";
import { createCancelTokenHandler } from "../../services/cancel-token.service";

interface IAdminVariantRepository
  extends IUpdateRepository<EditVariantPriceFormValues, ProductVariant> {}

const AdminVariantRepository: IAdminVariantRepository = {
  update(id, payload) {
    return api.put(`/api/admin/variant/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AdminVariantRepository);

export default AdminVariantRepository;
