import api from "../../services/api";
import { ISelectRepository } from "../../entities/repository";
import { Product } from "../../entities/product/product";
import { createCancelTokenHandler } from "../../services/cancel-token.service";

interface IMerchantCategoryRepository<T> extends ISelectRepository<T> {}

const MerchantCategoryRepository: IMerchantCategoryRepository<Product> = {
  select: function (params) {
    return api.get(`/api/merchant/select/category`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(MerchantCategoryRepository);

export default MerchantCategoryRepository;
