import api from "../../services/api";
import { IFindAllRepository, IFindOneRepository } from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { Customer } from "../../entities/customer/customer";

interface IMerchantCustomerRepository<T, R = T>
  extends IFindAllRepository<T, R>,
    IFindOneRepository<T, R> {}

const MerchantCustomerRepository: IMerchantCustomerRepository<Customer> = {
  findAll: function (params) {
    return api.get(`/api/merchant/customer`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/merchant/customer/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(MerchantCustomerRepository);

export default MerchantCustomerRepository;
