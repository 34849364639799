import api from "../../services/api";
import { IFindAllRepository, IFindOneRepository } from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { Customer } from "../../entities/customer/customer";

interface IAdminCustomerRepository<T, R = T>
  extends IFindAllRepository<T, R>,
    IFindOneRepository<T, R> {}

const AdminCustomerRepository: IAdminCustomerRepository<Customer> = {
  findAll: function (params) {
    return api.get(`/api/admin/customer`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/admin/customer/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AdminCustomerRepository);

export default AdminCustomerRepository;
