import { Paper, Stack } from "@mantine/core";
import { ArticleFormModuleProps, ArticleFormValues } from "./interface";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback, useEffect, useMemo, useRef } from "react";
import VuiRichTextEditor from "../../../components/vui-rich-text-editor";
import VuiSingleDropzone from "../../../components/vui-single-dropzone";
import { Media } from "../../../entities/media";
import VuiDatepicker from "../../../components/vui-datepicker";
import VuiSwitch from "../../../components/vui-switch";
import useFormArticle from "../hooks/useFormArticle";
import { backendDate } from "../../../helpers";
import VuiTextInput from "../../../components/vui-text-input";
import VuiActionForm from "../../../components/vui-action-form";
import { validationSchema } from "./validation";

const ArticleFormModule = (props: ArticleFormModuleProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const title = id
    ? t("pages.article.detail.title")
    : t("pages.article.add.title");

  const { submitLoading, submitData, data, getData, isFetchingData } =
    useFormArticle(t);
  const getDataRef = useRef(getData);

  const form = useForm<ArticleFormValues>({
    initialValues: {
      title: "",
      content: "",
      photo_id: null,
      date: "",
      is_active: false,
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleChangeImage = useCallback((value: Media | null) => {
    form.setFieldValue("photo_id", value ? value.id : value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    (values: ArticleFormValues) => {
      const normalizeValue: ArticleFormValues = {
        title: values.title,
        content: values.content,
        is_active: values.is_active,
        date: backendDate(values.date),
        photo_id:
          typeof values.photo_id === "number"
            ? values.photo_id
            : (values.photo_id?.id as number),
      };

      if (id) {
        Object.assign(normalizeValue, {
          id: id,
        });
      }

      submitData(normalizeValue, form.setFieldError);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, id]
  );

  useMemo(() => {
    if (id) {
      getDataRef.current(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setValues({
        ...data,
        id: data.id as string,
        photo_id: data.photo || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage title={title} backUrl={"/article"} />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <VuiTextInput
              label={t("form.title.label")}
              placeholder={t("form.title.placeholder")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("title")}
            />

            <VuiRichTextEditor
              skeletonLoading={isFetchingData}
              label={t("form.content.label")}
              {...form.getInputProps("content")}
            />

            <VuiSingleDropzone
              label={t("form.image.label")}
              onChangeImage={(value) => handleChangeImage(value)}
              onRemoveImage={() => handleChangeImage(null)}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("photo_id")}
            />

            <VuiDatepicker
              label={t("form.date.label")}
              placeholder={t("form.date.placeholder")}
              fullWidth={false}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("date")}
            />

            <VuiSwitch
              skeletonLoading={isFetchingData}
              formLabel={t("form.active.label")}
              {...form.getInputProps("is_active")}
            />
          </Stack>

          <VuiActionForm backUrl="/article" submitBtnLoading={submitLoading} />
        </form>
      </Paper>
    </>
  );
};

export default ArticleFormModule;
