import {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { User } from "../../entities/user";
import reducer, { ActionType } from "./reducer";

interface AuthInterface {
  user?: User;
  dispatch: React.Dispatch<ActionType>;
}

const AuthContext = createContext<AuthInterface | undefined>(undefined);

interface AuthProviderInterface {
  value?: User;
}

const AuthProvider = (props: PropsWithChildren<AuthProviderInterface>) => {
  const { children, value = {} as User } = props;
  const [user, dispatch] = useReducer(reducer, { ...value });

  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export const useIsAdmin = () => {
  const context = useAuth()

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context?.user?.role_user.role.name === 'Administrator'
}

export default AuthProvider;
