import { useState } from "react";
import { SelectItem } from "@mantine/core";
import MerchantProductRepository from "../repositories/merchant/product-repository";
import MerchantPaymentMethodRepository from "../repositories/merchant/payment-method-repository";
import MerchantCategoryRepository from "../repositories/merchant/category-repository";
import MerchantConstantRepository from "../repositories/merchant/constant-repository";
import AdminMerchantRepository from "../repositories/admin/merchant-repository";
import {useIsAdmin} from "../context/auth";
import AdminProductRepository from "../repositories/admin/product-repository";
import AdminCategoryRepository from "../repositories/admin/category-repository";
import AdminPaymentMethodRepository from "../repositories/admin/payment-method-repository";
import AdminConstantRepository from "../repositories/admin/constant-repository";

const useGetSelectOption = () => {
  const isAdmin = useIsAdmin()
  const [productData, setProductData] = useState<SelectItem[]>([]);
  const [paymentMethodData, setPaymentMethodData] = useState<SelectItem[]>([]);
  const [categoryData, setCategoryData] = useState<SelectItem[]>([]);
  const [orderStatusData, setOrderStatusData] = useState<SelectItem[]>([]);
  const [merchantData, setMerchantData] = useState<SelectItem[]>([]);

  const loadProductData = async () => {
    await (isAdmin ? AdminProductRepository : MerchantProductRepository).select().then((response) => {
      const { data: responseData } = response.data;
      setProductData(responseData);
    });
  };

  const loadPaymentMethodData = async () => {
    await (isAdmin ? AdminPaymentMethodRepository : MerchantPaymentMethodRepository).select().then((response) => {
      const { data: responseData } = response.data;
      setPaymentMethodData(responseData);
    });
  };

  const loadCategoryData = async () => {
    await (isAdmin ? AdminCategoryRepository : MerchantCategoryRepository).select().then((response) => {
      const { data: responseData } = response.data;
      setCategoryData(responseData);
    });
  };

  const loadOrderStatusData = async () => {
    await (isAdmin ? AdminConstantRepository : MerchantConstantRepository).select({
      for: "order_status",
    }).then((response) => {
      const { data: responseData } = response.data;
      setOrderStatusData(responseData);
    });
  };

  const loadMerchantData = async () => {
    await AdminMerchantRepository.select().then((response) => {
      const { data: responseData } = response.data;
      setMerchantData(responseData);
    });
  };

  return {
    productData,
    loadProductData,
    paymentMethodData,
    loadPaymentMethodData,
    categoryData,
    loadCategoryData,
    orderStatusData,
    loadOrderStatusData,
    merchantData,
    loadMerchantData
  };
};

export default useGetSelectOption;
