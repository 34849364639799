import { Paper, Stack } from "@mantine/core";
import { OrderFormModuleProps, OrderFormValues } from "./interface";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useEffect, useMemo, useRef } from "react";
import VuiTextInput from "../../../components/vui-text-input";
import VuiActionForm from "../../../components/vui-action-form";
import useFormOrder from "../hooks/useFormOrder";
import VuiCurrencyInput from "../../../components/vui-currency-input";
import VuiDatepicker from "../../../components/vui-datepicker";
import {useIsAdmin} from "../../../context/auth";

const OrderFormModule = (props: OrderFormModuleProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();

  const { data, getData, isFetchingData } = useFormOrder(t);
  const getDataRef = useRef(getData);

  const form = useForm<OrderFormValues>({
    initialValues: {
      customer: null,
      id: "",
      date: "",
      status: null,
      game_user_id: null,
      grand_total: 0,
      price: 0,
      priceBeforeDiscount: 0,
      promo: null,
      promo_amount: 0,
      server_id: null,
      server_name: null,
      variant: null,
      payment: null,
      merchant: null
    },
  });

  useMemo(() => {
    if (id) {
      getDataRef.current(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setValues(data);
      form.setFieldValue('priceBeforeDiscount', (data.price+data.transaction_fee));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage title={t("pages.order.detail.title")} backUrl={"/order"} />

      <Paper shadow="xs" radius="md" p="xl">
        <form>
          <Stack>
            {
              isAdmin ? (
                  <VuiTextInput
                      label={t("form.merchant.label")}
                      placeholder={t("form.merchant.placeholder")}
                      skeletonLoading={isFetchingData}
                      disabled
                      value={form.values.merchant?.name}
                  />
              ) : ''
            }

            <VuiTextInput
              label={t("form.customer.label")}
              placeholder={t("form.customer.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.customer?.email}
            />

            <VuiCurrencyInput
              label={t("form.price.label")}
              placeholder={t("form.price.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.grand_total}
            />

            <VuiCurrencyInput
                label={t("form.priceBeforeDiscount.label")}
                placeholder={t("form.priceBeforeDiscount.placeholder")}
                skeletonLoading={isFetchingData}
                disabled
                value={form.values.priceBeforeDiscount}
            />

            <VuiTextInput
              label={t("form.product.label")}
              placeholder={t("form.product.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.variant?.product.name}
            />

            <VuiTextInput
              label={t("form.variant.label")}
              placeholder={t("form.variant.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.variant?.name}
            />

            <VuiTextInput
              label={t("form.promoCode.label")}
              placeholder={t("form.promoCode.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.promo?.code || "-"}
            />

            <VuiTextInput
                label={t("form.promoAmount.label")}
                placeholder={t("form.promoAmount.placeholder")}
                skeletonLoading={isFetchingData}
                disabled
                value={form.values.promo_amount}
            />

            <VuiTextInput
              label={t("form.paymentMethod.label")}
              placeholder={t("form.paymentMethod.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.payment?.name}
            />

            <VuiTextInput
              label={t("form.status.label")}
              placeholder={t("form.status.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={form.values.status?.label}
            />

            <VuiDatepicker
              label={t("form.date.label")}
              placeholder={t("form.date.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={new Date(form.values.date)}
            />

            <VuiTextInput
              label={t("form.userId.label")}
              placeholder={t("form.userId.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={String(form.values.game_user_id || "-")}
            />

            <VuiTextInput
              label={t("form.serverId.label")}
              placeholder={t("form.serverId.placeholder")}
              skeletonLoading={isFetchingData}
              disabled
              value={String((form.values.server_id || form.values.server_name) || "-")}
            />
          </Stack>

          <VuiActionForm backUrl="/order" forSubmit={false} />
        </form>
      </Paper>
    </>
  );
};

export default OrderFormModule;
