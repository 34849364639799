import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ResetPasswordSuccessModule from "../../../modules/reset-password-success";

const AuthResetPasswordSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.resetPasswordSuccess.metaTitle")} />

      <ResetPasswordSuccessModule />
    </>
  );
};

export default AuthResetPasswordSuccessPage;
