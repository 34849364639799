import { Box, MultiSelect, Skeleton } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { VuiMultiSelectProps } from "./interface";

const VuiMultiSelect = (props: VuiMultiSelectProps) => {
  const { t } = useTranslation();
  const {
    nothingFound = t("text.notFound"),
    placeholder = t("select.default"),
    clearable = true,
    maxDropdownHeight = 240,
    dropdownPosition = "flip",
    skeletonLoading = false,
    ...other
  } = props;

  return (
    <Box
      sx={{
        "@media (max-width: 768px)": {
          width: "100%",
        },
      }}
    >
      {skeletonLoading ? (
        <Skeleton visible={skeletonLoading} height={42} />
      ) : (
        <MultiSelect
          {...other}
          placeholder={placeholder}
          clearable={clearable}
          nothingFound={nothingFound}
          maxDropdownHeight={maxDropdownHeight}
          dropdownPosition={dropdownPosition}
        />
      )}
    </Box>
  );
};

export default VuiMultiSelect;
