import { Box, Skeleton, Text } from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";
import { VuiRichTextEditorProps } from "./interface";
import { styLabel } from "./style";
import VuiFormError from "../vui-form-error";
import { useCallback, useRef } from "react";
import { MediaForm } from "../../entities/media";
import useMedia from "../../hooks/useMedia";

const VuiRichTextEditor = (props: VuiRichTextEditorProps) => {
  const { label, skeletonLoading = false, error, ...other } = props;
  const { storeMedia } = useMedia();
  const storeMediaRef = useRef(storeMedia);

  const handleImageUpload = useCallback(
    (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const formData: MediaForm = {
          files: [file],
          path: "file",
          disk: "public",
        };

        storeMediaRef
          .current(formData)
          .then((response: any) => resolve(response.url))
          .catch(() => reject(new Error("Upload failed")));
      }),
    []
  );

  return (
    <Skeleton visible={skeletonLoading}>
      <Box>
        {label && <Text sx={styLabel}>{label}</Text>}

        <RichTextEditor
          {...other}
          sx={{
            borderColor: error ? "red" : "gray.1",
            "& .mantine-RichTextEditor-toolbar": {
              borderColor: error ? "red" : "gray.1",
            },
          }}
          onImageUpload={handleImageUpload}
        />

        {error && <VuiFormError message={error} />}
      </Box>
    </Skeleton>
  );
};

export default VuiRichTextEditor;
