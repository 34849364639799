import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ArticleFormModule from "../../../../modules/article/form";

const AppArticleFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const metaTitle = id
    ? t("pages.article.detail.metaTitle")
    : t("pages.article.add.metaTitle");

  return (
    <>
      <VuiHelmet title={metaTitle} />

      <ArticleFormModule id={id} />
    </>
  );
};

export default AppArticleFormPage;
