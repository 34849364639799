import { Global } from "@mantine/core";

const GlobalStyles = () => {
  return (
    <Global
      styles={(theme) => ({
        "*, *::before, *::after": {
          boxSizing: "border-box",
        },

        "body, html": {
          padding: 0,
          margin: 0,
          scrollBehavior: "smooth",
        },

        body: {
          position: "relative",
        },

        a: {
          _focus: {
            boxShadow: "unset !important",
          },
          _hover: {
            textDecoration: "none !important",
          },
        },
      })}
    />
  );
};

export default GlobalStyles;
