import { Button, Group, MediaQuery, SelectItem } from "@mantine/core";
import VuiTable from "../../components/vui-table";
import { TableColumn } from "../../components/vui-table/interface";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VuiSelect from "../../components/vui-select";
import VuiSearch from "../../components/vui-search";
import { useDebounce } from "usehooks-ts";
import { BaseSort, OrderDirection } from "../../entities/query-params";
import { DEFAULT_PER_PAGE } from "../../constant";
import { Link } from "react-router-dom";
import VuiConfirmationModal from "../../components/vui-confirmation-modal";
import VuiHeaderPage from "../../components/vui-header-page";
import { IconPlus } from "@tabler/icons";
import useGetPromo from "./hooks/useGetPromo";
import VuiActionTable from "../../components/vui-action-table";
import useFormPromo from "./hooks/useFormPromo";
import { Promo } from "../../entities/promo";
import {useIsAdmin} from "../../context/auth";
import useGetSelectOption from "../../hooks/useGetSelectOption";

const PromoModule = () => {
  const isAdmin = useIsAdmin()
  const { t } = useTranslation();
  const { dataSource, loadData, loading, total } = useGetPromo(t);
  const loadDataRef = useRef(loadData);
  const { deleteData, deleteLoading } = useFormPromo(t);

  const {merchantData,loadMerchantData} = useGetSelectOption()
  const loadMerchantDataRef = useRef(loadMerchantData);
  const [selectedMerchant, setSelectedMerchant] = useState<string | null>(
      null
  );

  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const sortOptions: SelectItem[] = [
    {
      value: "code-asc",
      label: t("select.sortAsc", { item: t("text.code") }),
    },
    {
      value: "code-desc",
      label: t("select.sortDesc", { item: t("text.code") }),
    },
    {
      value: "name-asc",
      label: t("select.sortAsc", { item: t("text.name") }),
    },
    {
      value: "name-desc",
      label: t("select.sortDesc", { item: t("text.name") }),
    },
  ];

  const [selectedId, setSelectedId] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleCloseConfirmModal = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);
  const handleDelete = useCallback((id: number) => {
    setSelectedId(id);
    setOpenConfirmModal(true);
  }, []);

  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sort, setSort] = useState<BaseSort>({
    order_by: "name",
    sorted_by: "asc",
  });
  const debouncedValue = useDebounce<string>(searchValue, 250);

  const columns: TableColumn<Promo>[] = [
    { key: "code", dataIndex: "code", title: t("text.code") },
    { key: "name", dataIndex: "name", title: t("text.name") },
    { key: "type_name", dataIndex: "type_name", title: t("text.type") },
    {
      key: "action",
      dataIndex: "id",
      title: "",
      render: (value, values) => (
        <VuiActionTable
          detailUrl={`/promo/${value}`}
          onDelete={() => handleDelete(Number(value))}
          canDelete={values.can_delete && !isAdmin}
        />
      ),
    },
  ];

  if (isAdmin) {
    columns.splice(3, 0, {
      key: "merchant_name", dataIndex: "merchant_name", title: t("text.merchant")
    })
  }

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        merchant: selectedMerchant
      });
    },
    [debouncedValue, sort, selectedMerchant]
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleChangeSort = useCallback(
    (value: string) => {
      const splitValue = value.split("-");
      setSort({
        order_by: splitValue[0],
        sorted_by: splitValue[1] as OrderDirection,
      });

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: splitValue[1] as OrderDirection,
        order_by: splitValue[0],
        merchant: selectedMerchant
      });
    },
    [debouncedValue, page, selectedMerchant]
  );

  const handleChangeMerchant = useCallback(
      (value: string | null) => {
        setSelectedMerchant(value);
        setPage(1);

        loadDataRef.current({
          page: page,
          per_page: DEFAULT_PER_PAGE,
          search: debouncedValue,
          sorted_by: sort.sorted_by,
          order_by: sort.order_by,
          merchant: value
        });
      },
      [page, sort, debouncedValue]
  );

  const handleConfirmDelete = useCallback(() => {
    deleteData(selectedId).then(() => {
      handleCloseConfirmModal();

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
        merchant: selectedMerchant
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, debouncedValue, sort, page, selectedMerchant]);

  useEffect(() => {
    if (!hasInitialized) {
      loadMerchantDataRef.current();

      setHasInitialized(true);
    }

    loadDataRef.current({
      page: 1,
      per_page: DEFAULT_PER_PAGE,
      search: debouncedValue,
      sorted_by: sort.sorted_by,
      order_by: sort.order_by,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <>
      <VuiConfirmationModal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        onSubmitLoading={deleteLoading}
      />

      <VuiHeaderPage title={t("pages.promo.title")}>
        {
          !isAdmin ? (
              <Group>
                <Button
                    leftIcon={<IconPlus size={18} />}
                    to={"/promo/add"}
                    component={Link}
                >
                  {t("button.add")}
                </Button>
              </Group>
          ) : ''
        }
      </VuiHeaderPage>

      <Group position="apart" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSearch value={searchValue} onChange={handleSearch} />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.sort")}
            onChange={handleChangeSort}
            data={sortOptions}
          />
        </MediaQuery>
      </Group>

      {
        isAdmin ? (
            <Group position="left" mb="md">
              <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                <VuiSelect
                    placeholder={t("select.merchant")}
                    onChange={handleChangeMerchant}
                    data={merchantData}
                />
              </MediaQuery>
            </Group>
        ) : ''
      }

      <VuiTable
        totalPage={total}
        onChangePage={handleChangePage}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        page={page}
      />
    </>
  );
};

export default PromoModule;
