import { Paper, Stack } from "@mantine/core";
import { UserFormModuleProps, UserFormValues } from "./interface";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback, useEffect, useMemo, useRef } from "react";
import VuiTextInput from "../../../components/vui-text-input";
import VuiPasswordInput from "../../../components/vui-password-input";
import useFormUser from "../hooks/useFormUser";
import { validationSchema } from "./validation";
import VuiActionForm from "../../../components/vui-action-form";
import {
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../helpers";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

const UserFormModule = (props: UserFormModuleProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const title = id ? t("pages.user.detail.title") : t("pages.user.add.title");

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const { isFetchingData, submitData, data, getData, submitLoading } =
    useFormUser(t);
  const getDataRef = useRef(getData);
  const form = useForm<UserFormValues>({
    initialValues: {
      email: "",
      name: "",
      password: "",
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(
    async (values: UserFormValues) => {
      submitData(values)
        .then(() => {
          handleSaveSuccessResponse(t, t("text.user"));
          navigateRef.current("/user");
        })
        .catch((error: AxiosError) => {
          handleSaveErrorResponse(t, error);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  useMemo(() => {
    if (id) {
      getDataRef.current(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setValues({
        ...data,
        name: data.name || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage title={title} backUrl={"/user"} />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <VuiTextInput
              skeletonLoading={isFetchingData}
              label={t("form.name.label")}
              placeholder={t("form.name.placeholder")}
              {...form.getInputProps("name")}
            />

            <VuiTextInput
              skeletonLoading={isFetchingData}
              label={t("form.email.label")}
              placeholder={t("form.email.placeholder")}
              {...form.getInputProps("email")}
            />

            {!id && (
              <VuiPasswordInput
                skeletonLoading={isFetchingData}
                label={t("form.password.label")}
                placeholder={t("form.password.placeholder")}
                {...form.getInputProps("password")}
              />
            )}
          </Stack>

          <VuiActionForm backUrl="/user" submitBtnLoading={submitLoading} />
        </form>
      </Paper>
    </>
  );
};

export default UserFormModule;
