import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MerchantFormModule from "../../../../modules/merchant/form";

const AppMerchantFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <VuiHelmet title={t("pages.merchant.detail.metaTitle")} />

      <MerchantFormModule id={id}/>
    </>
  );
};

export default AppMerchantFormPage;
