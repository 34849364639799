import { useRef, useState } from "react";
import {
  handleDeleteDataResponse,
  handleLoadDataErrorResponse,
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../helpers";
import { TFunction } from "react-i18next";
import MerchantPromoRepository from "../../../repositories/merchant/promo-repository";
import { PromoFormValues } from "../form/interface";
import { useNavigate } from "react-router-dom";
import { SetFieldError } from "@mantine/form/lib/types";
import {useIsAdmin} from "../../../context/auth";
import AdminPromoRepository from "../../../repositories/admin/promo-repository";

const useFormPromo = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [data, setData] = useState<PromoFormValues>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const submitData = (
    data: PromoFormValues,
    setError: SetFieldError<PromoFormValues>
  ) => {
    setSubmitLoading(true);

    new Promise((resolve, reject) => {
      if (data.id) {
        (isAdmin ? AdminPromoRepository : MerchantPromoRepository).update(data.id, data).then(resolve).catch(reject);
      } else {
        (isAdmin ? AdminPromoRepository : MerchantPromoRepository).create(data).then(resolve).catch(reject);
      }
    })
      .then(() => {
        handleSaveSuccessResponse(t, t("text.promo"), Boolean(data.id));
        navigateRef.current("/promo");
      })
      .catch((error) => {
        handleSaveErrorResponse(t, error, setError);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const getData = (id: number) => {
    setIsFetchingData(true);

    (isAdmin ? AdminPromoRepository : MerchantPromoRepository).findOne(id, {
      with: ['merchant']
    })
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData as PromoFormValues);
      })
      .catch(() => {
        setIsErrorOnFetching(true);
        handleLoadDataErrorResponse(t, t("text.promo"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const deleteData = (id: number) => {
    setDeleteLoading(true);

    return new Promise((resolve, reject) => {
      (isAdmin ? AdminPromoRepository : MerchantPromoRepository).delete(id)
        .then(() => {
          handleDeleteDataResponse(t, t("text.promo"));
          resolve(true);
        })
        .catch((error) => {
          handleDeleteDataResponse(t, t("text.promo"), false);
          reject(error);
        })
        .finally(() => {
          setDeleteLoading(false);
        });
    });
  };

  return {
    data,
    isFetchingData,
    isErrorOnFetching,
    getData,
    deleteData,
    deleteLoading,
    submitLoading,
    submitData,
  };
};

export default useFormPromo;
