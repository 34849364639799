import { Box, Text } from "@mantine/core";
import { useAuth } from "../../context/auth";

const HomeModule = () => {
  const { user } = useAuth();

  return (
    <Box>
      <Text size="lg">Welcome {user?.name}</Text>
    </Box>
  );
};

export default HomeModule;
