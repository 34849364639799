import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import PageModule from "../../../modules/page";

const AppPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.page.metaTitle")} />

      <PageModule />
    </>
  );
};

export default AppPage;
