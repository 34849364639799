import { CSSObject } from "@mantine/core";
import { pxToRem } from "../../helpers";

export const styBoxWrapper = (): CSSObject => ({
  maxWidth: pxToRem(480),
  height: "100vh",
  width: "100%",
  margin: "auto",
});

export const styHeaderWrapper = (): CSSObject => ({
  marginBottom: pxToRem(28),
  textAlign: "center",
});
