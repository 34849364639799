import { useState } from "react";
import { TFunction } from "react-i18next";
import {EditVariantPriceFormValues, UpdateVariantStatus} from "../components/edit-variant-price-modal/interface";
import MerchantVariantRepository from "../../../repositories/merchant/variant-repository";
import { ProductVariant } from "../../../entities/product/product-variant";
import {useIsAdmin} from "../../../context/auth";
import AdminVariantRepository from "../../../repositories/admin/variant-repository";

const useFormVariant = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState<boolean>(false);

  const submitData = (id: number, data: EditVariantPriceFormValues) => {
    setSubmitLoading(true);

    return new Promise<ProductVariant>((resolve, reject) => {
      (isAdmin ? AdminVariantRepository : MerchantVariantRepository).update(String(id), data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    });
  };

  const updateStatus = (id: number, data: UpdateVariantStatus) => {
    setUpdateStatusLoading(true);

    return new Promise<ProductVariant>((resolve, reject) => {
      MerchantVariantRepository.updateStatus(String(id), data)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            setUpdateStatusLoading(false);
          });
    })
  }

  return {
    submitData,
    submitLoading,
    updateStatusLoading,
    updateStatus
  };
};

export default useFormVariant;
