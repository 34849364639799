import {useTranslation} from "react-i18next";
import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {useDebounce} from "usehooks-ts";
import {BaseSort, OrderDirection} from "../../../entities/query-params";
import {Group, MediaQuery, SelectItem} from "@mantine/core";
import {TableColumn} from "../../../components/vui-table/interface";
import {DEFAULT_PER_PAGE} from "../../../constant";
import VuiHeaderPage from "../../../components/vui-header-page";
import VuiSearch from "../../../components/vui-search";
import VuiSelect from "../../../components/vui-select";
import VuiTable from "../../../components/vui-table";
import VuiDateRangePicker from "../../../components/vui-date-range-picker";
import {DateRangePickerValue} from "@mantine/dates";
import {backendDate, subtractDays} from "../../../helpers";
import useGetReportMerchant from "./hooks/useGetReportMerchant";

const ReportMerchantModule = () => {
    const { t } = useTranslation();

    const { dataSource, loadData, loading, total } = useGetReportMerchant(t);
    const loadDataRef = useRef(loadData);

    const [selectedDate, setSelectedDate] = useState<DateRangePickerValue>([
        subtractDays(7),
        new Date(),
    ]);

    const [page, setPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedValue = useDebounce<string>(searchValue, 250);

    const [sort, setSort] = useState<BaseSort>({
        order_by: "name",
        sorted_by: "asc",
    });

    const sortOptions: SelectItem[] = [
        {
            value: "name-asc",
            label: t("select.sortAsc", { item: t("text.name") }),
        },
        {
            value: "name-desc",
            label: t("select.sortDesc", { item: t("text.name") }),
        },
    ];

    const columns: TableColumn[] = [
        { key: "name", dataIndex: "merchant_name", title: t("text.name") },
        { key: "totalTransaction", dataIndex: "total_transaction", type: "currency", title: t("text.totalTransaction") },
        { key: "totalTransactionFee", dataIndex: "total_fee", type: "currency", title: t("text.totalTransactionFee") },
        { key: "totalModal", dataIndex: "total_modal", type: "currency", title: t("text.totalModal") },
        { key: "totalProfit", dataIndex: "total_profit", type: "currency", title: t("text.totalProfit") },
    ]

    const handleChangePage = useCallback(
        (page: number) => {
            setPage(page);

            loadDataRef.current({
                page: page,
                per_page: DEFAULT_PER_PAGE,
                search: debouncedValue,
                sorted_by: sort.sorted_by,
                order_by: sort.order_by,
                date_from: backendDate(selectedDate[0]),
                date_to: backendDate(selectedDate[1]),
            });
        },
        [sort, debouncedValue]
    );

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.currentTarget.value);
    }, []);

    const handleChangeSort = useCallback(
        (value: string) => {
            const splitValue = value.split("-");
            setSort({
                order_by: splitValue[0],
                sorted_by: splitValue[1] as OrderDirection,
            });

            loadDataRef.current({
                page: page,
                per_page: DEFAULT_PER_PAGE,
                search: debouncedValue,
                sorted_by: splitValue[1] as OrderDirection,
                order_by: splitValue[0],
                date_from: backendDate(selectedDate[0]),
                date_to: backendDate(selectedDate[1]),
            });
        },
        [page, debouncedValue]
    );

    const handleChangeDate = useCallback(
        (value: DateRangePickerValue) => {
            setSelectedDate(value);
            setPage(1);

            loadDataRef.current({
                page: 1,
                per_page: DEFAULT_PER_PAGE,
                search: debouncedValue,
                sorted_by: sort.sorted_by,
                order_by: sort.order_by,
                date_from: backendDate(value[0]),
                date_to: backendDate(value[1]),
            });
        },
        [
            sort,
            debouncedValue,
        ]
    );

    useEffect(() => {
        loadDataRef.current({
            page: 1,
            per_page: DEFAULT_PER_PAGE,
            search: debouncedValue,
            sorted_by: sort.sorted_by,
            order_by: sort.order_by,
            date_from: backendDate(selectedDate[0]),
            date_to: backendDate(selectedDate[1]),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
        <>
            <VuiHeaderPage title={t("pages.reportMerchant.title")}/>

            <Group position="apart" mb="md">
                <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                    <VuiSearch value={searchValue} onChange={handleSearch} />
                </MediaQuery>

                <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                    <VuiSelect
                        placeholder={t("select.sort")}
                        onChange={handleChangeSort}
                        data={sortOptions}
                    />
                </MediaQuery>
            </Group>

            <Group position="left" mb="md">
                <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
                    <VuiDateRangePicker
                        placeholder={t("select.date")}
                        value={selectedDate}
                        onChange={handleChangeDate}
                        fullWidth={false}
                    />
                </MediaQuery>
            </Group>

            <VuiTable
                totalPage={total}
                onChangePage={handleChangePage}
                isLoading={loading}
                dataSource={dataSource}
                columns={columns}
                page={page}
            />
        </>
    )
}

export default ReportMerchantModule