import api from "../../services/api";
import { ISelectRepository } from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { PaymentMethod } from "../../entities/payment-method";

interface IAdminPaymentMethodRepository extends ISelectRepository<PaymentMethod> {}

const AdminPaymentMethodRepository: IAdminPaymentMethodRepository = {
  select: function (params) {
    return api.get(`/api/admin/select/payment-method`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  AdminPaymentMethodRepository
);

export default AdminPaymentMethodRepository;
