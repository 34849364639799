import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import UserFormModule from "../../../../modules/user/form";
import { useParams } from "react-router-dom";

const AppUserFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const metaTitle = id
    ? t("pages.user.detail.metaTitle")
    : t("pages.user.add.metaTitle");

  return (
    <>
      <VuiHelmet title={metaTitle} />

      <UserFormModule id={id} />
    </>
  );
};

export default AppUserFormPage;
