import {BaseRepository, IFindAllRepository, IFindOneRepository, ISelectRepository} from "../../entities/repository";
import {createCancelTokenHandler} from "../../services/cancel-token.service";
import api from "../../services/api";

interface IAdminMerchantRepository<T,  R = T>
    extends BaseRepository<T, R>,
        ISelectRepository<T> {}

const AdminMerchantRepository: IAdminMerchantRepository<any> = {
    findAll: function (params) {
        return api.get(`/api/admin/merchant`, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
                .token,
        });
    },
    findOne: function (id, params) {
        return api.get(`/api/admin/merchant/${id}`, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
                .token,
        });
    },
    create(payload) {
        return api.post(`/api/admin/merchant`, payload, {
            cancelToken:
            cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
                .token,
        });
    },
    update(id, payload) {
        return api.put(`/api/admin/merchant/${id}`, payload, {
            cancelToken:
            cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
                .token,
        });
    },
    delete: function (id) {
        return api.delete(`/api/admin/merchant/${id}`, {
            cancelToken:
            cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
                .token,
        });
    },
    select: function (params) {
        return api.get(`/api/admin/select/merchant`, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
                .token,
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(AdminMerchantRepository);

export default AdminMerchantRepository