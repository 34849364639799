import { Skeleton, TextInput } from "@mantine/core";
import { VuiTextInputProps } from "./interface";

const VuiTextInput = (props: VuiTextInputProps) => {
  const { skeletonLoading = false, ...inputProps } = props;
  return (
    <Skeleton visible={skeletonLoading}>
      <TextInput {...inputProps} />
    </Skeleton>
  );
};

export default VuiTextInput;
