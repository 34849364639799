import { Paper, Stack } from "@mantine/core";
import { SettingFormValues } from "./interface";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import VuiHeaderPage from "../../components/vui-header-page";
import VuiTextInput from "../../components/vui-text-input";
import { useFormSetting } from "./hooks/useFormSetting";
import VuiActionForm from "../../components/vui-action-form";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useGetSetting } from "./hooks/useGetSetting";
import VuiSingleDropzone from "../../components/vui-single-dropzone";
import {Media} from "../../entities/media";

const SettingModule = () => {
  const { t } = useTranslation();
  const { submitLoading, submitData } = useFormSetting(t);
  const { data, getData, isFetchingData } = useGetSetting(t);
  const getDataRef = useRef(getData);

  const form = useForm<SettingFormValues>({
    initialValues: {
      company_name: "",
      whatsapp_link: "",
      instagram_link: "",
      logo_id: null,
      logo: null,
      logo_mobile_id: null,
      logo_mobile: null,
    },
  });

  const handleSubmit = useCallback(
    (data: SettingFormValues) => {
      const normalizeValue = {
        company_name: data.company_name,
        whatsapp_link: data.whatsapp_link,
        instagram_link: data.instagram_link,
        logo_id:
            typeof data.logo_id === "number"
                ? data.logo_id
                : data.logo_id?.id,
        logo_mobile_id:
            typeof data.logo_mobile_id === "number"
                ? data.logo_mobile_id
                : data.logo_mobile_id?.id,
      };

      submitData(normalizeValue, form.setFieldError);
    },
    [form, submitData]
  );

  const handleChangeLogo = useCallback(
      (value: Media | null) => {
        form.setFieldValue("logo_id", value ? value.id : value);
      },
      [form]
  );

  const handleChangeLogoMobile = useCallback(
      (value: Media | null) => {
        form.setFieldValue("logo_mobile_id", value ? value.id : value);
      },
      [form]
  );

  useMemo(() => {
    getDataRef.current();
  }, []);

  useEffect(() => {
    if (data) {
      form.setValues({
        ...data,
        logo_id: data.logo,
        logo_mobile_id: data.logo_mobile,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <VuiHeaderPage title={t("pages.setting.title")} />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <VuiTextInput
                label={t("form.companyName.label")}
                placeholder={t("form.companyName.placeholder")}
                skeletonLoading={isFetchingData}
                {...form.getInputProps("company_name")}
            />

            <VuiSingleDropzone
                label={t("form.logo.label")}
                skeletonLoading={isFetchingData}
                onChangeImage={(value) => handleChangeLogo(value)}
                onRemoveImage={() => handleChangeLogo(null)}
                {...form.getInputProps("logo_id")}
            />

            <VuiSingleDropzone
                label={t("form.logoMobile.label")}
                skeletonLoading={isFetchingData}
                onChangeImage={(value) => handleChangeLogoMobile(value)}
                onRemoveImage={() => handleChangeLogoMobile(null)}
                {...form.getInputProps("logo_mobile_id")}
            />

            <VuiTextInput
              label={t("form.whatsappLink.label")}
              placeholder={t("form.whatsappLink.placeholder")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("whatsapp_link")}
            />

            <VuiTextInput
              label={t("form.instagramLink.label")}
              placeholder={t("form.instagramLink.placeholder")}
              skeletonLoading={isFetchingData}
              {...form.getInputProps("instagram_link")}
            />
          </Stack>

          <VuiActionForm submitBtnLoading={submitLoading} />
        </form>
      </Paper>
    </>
  );
};

export default SettingModule;
