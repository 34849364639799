import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ReportMerchantModule from "../../../../modules/report/merchant";

const AppReportMerchantPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <VuiHelmet title={t("pages.reportMerchant.metaTitle")} />

            <ReportMerchantModule/>
        </>
    );
};

export default AppReportMerchantPage;
