import { useRef, useState } from "react";
import {
  handleLoadDataErrorResponse,
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../helpers";
import { TFunction } from "react-i18next";
import MerchantProductRepository from "../../../repositories/merchant/product-repository";
import { Product } from "../../../entities/product/product";
import { useNavigate } from "react-router-dom";
import { SetFieldError } from "@mantine/form/lib/types";
import { ProductFormValues } from "../form/interface";
import {useIsAdmin} from "../../../context/auth";
import AdminProductRepository from "../../../repositories/admin/product-repository";

const useFormProduct = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [data, setData] = useState<Product>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const submitData = (
    data: Partial<ProductFormValues>,
    setError: SetFieldError<Partial<ProductFormValues>>
  ) => {
    setSubmitLoading(true);

    new Promise((resolve, reject) => {
      if (data.id) {
        (isAdmin ? AdminProductRepository : MerchantProductRepository).update(data.id as string, data)
          .then(resolve)
          .catch(reject);
      }
    })
      .then(() => {
        handleSaveSuccessResponse(t, t("text.product"), Boolean(data.id));
        navigateRef.current("/product");
      })
      .catch((error) => {
        handleSaveErrorResponse(t, error, setError);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const getData = (id: number) => {
    setIsFetchingData(true);

    (isAdmin ? AdminProductRepository : MerchantProductRepository).findOne(id, {
      with: ["photo", "variants", "servers"],
    })
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData);
      })
      .catch(() => {
        setIsErrorOnFetching(true);
        handleLoadDataErrorResponse(t, t("text.product"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  return {
    data,
    isFetchingData,
    isErrorOnFetching,
    getData,
    submitLoading,
    submitData,
  };
};

export default useFormProduct;
