import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    email: Yup.string().email(t("validation.email.invalid")).required(),
  };

  return Yup.object().shape(shapes);
};
