import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import PromoModule from "../../../modules/promo";

const AppPromoPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.promo.metaTitle")} />

      <PromoModule />
    </>
  );
};

export default AppPromoPage;
