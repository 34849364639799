import { SettingFormValues } from "../interface";
import SettingRepository from "../../../repositories/setting-repository";
import {
  handleSaveErrorResponse,
  handleSaveSuccessResponse,
} from "../../../helpers";
import { TFunction } from "react-i18next";
import { SetFieldError } from "@mantine/form/lib/types";
import { useState } from "react";

export const useFormSetting = (t: TFunction) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitData = (
    data: Partial<SettingFormValues>,
    setError: SetFieldError<Partial<SettingFormValues>>
  ) => {
    setSubmitLoading(true);

    new Promise((resolve, reject) => {
      SettingRepository.create(data).then(resolve).catch(reject);
    })
      .then(() => {
        handleSaveSuccessResponse(t, t("text.setting"), true);
      })
      .catch((error) => {
        handleSaveErrorResponse(t, error, setError);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return {
    submitData,
    submitLoading,
  };
};
