import { Paper, Stack } from "@mantine/core";
import { ProfileFormValues } from "./interface";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import VuiHeaderPage from "../../components/vui-header-page";
import { useAuth } from "../../context/auth";
import VuiTextInput from "../../components/vui-text-input";

const ProfileModule = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const form = useForm<ProfileFormValues>({
    initialValues: user,
  });

  return (
    <>
      <VuiHeaderPage title={t("pages.profile.title")} />

      <Paper shadow="xs" radius="md" p="xl">
        <form>
          <Stack>
            <VuiTextInput
              disabled
              label={t("form.name.label")}
              placeholder={t("form.name.placeholder")}
              {...form.getInputProps("name")}
            />

            <VuiTextInput
              disabled
              label={t("form.email.label")}
              placeholder={t("form.email.placeholder")}
              {...form.getInputProps("email")}
            />
          </Stack>
        </form>
      </Paper>
    </>
  );
};

export default ProfileModule;
