import { Box, Center, Container, Paper, Text, Title } from "@mantine/core";
import { PropsWithChildren } from "react";
import { styBoxWrapper, styHeaderWrapper } from "./style";
import { AuthModuleProps } from "./interface";
import { pxToRem } from "../../helpers";

const AuthModule = (props: PropsWithChildren<AuthModuleProps>) => {
  const { children, title, description } = props;

  return (
    <Container>
      <Center sx={styBoxWrapper}>
        <Paper sx={{ width: "100%" }} radius="md" p="xl">
          <Box sx={styHeaderWrapper}>
            <img
              style={{ margin: `${pxToRem(8)} auto` }}
              width={180}
              src="/assets/images/logo.png"
              alt="Logo"
            />

            {title && (
              <Title sx={{ fontWeight: 600 }} mb="sm" order={2}>
                {title}
              </Title>
            )}

            {description && <Text color="gray.7">{description}</Text>}
          </Box>

          {children}
        </Paper>
      </Center>
    </Container>
  );
};

export default AuthModule;
