import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ProductModule from "../../../modules/product";

const AppProductPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.product.metaTitle")} />

      <ProductModule />
    </>
  );
};

export default AppProductPage;
