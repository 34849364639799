import LoginModule from "../../../modules/login";
import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";

const AuthLoginPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.login.metaTitle")} />

      <LoginModule />
    </>
  );
};

export default AuthLoginPage;
