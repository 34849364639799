import { User } from "../../entities/user";

export type ActionType = {
  type: "UPDATE_USER";
  payload: User;
};

function reducer(state: User, action: ActionType) {
  switch (action.type) {
    case "UPDATE_USER":
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
