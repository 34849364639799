import api from "../../services/api";
import { BaseRepository } from "../../entities/repository";
import { User } from "../../entities/user";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { UserFormValues } from "../../modules/user/form/interface";

const AdminUserRepository: BaseRepository<UserFormValues, User> = {
  findAll: function (params) {
    return api.get(`/api/admin/user`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/admin/user/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  create(payload) {
    return api.post(`/api/admin/user`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update(id, payload) {
    return api.put(`/api/admin/user/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id) {
    return api.delete(`/api/admin/user/${id}`, {
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AdminUserRepository);

export default AdminUserRepository;
