import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import MerchantModule from "../../../modules/merchant";

const AppMerchantPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <VuiHelmet title={t("pages.merchant.metaTitle")} />

            <MerchantModule/>
        </>
    );
};

export default AppMerchantPage;
