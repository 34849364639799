import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ForgotPasswordSuccessModule from "../../../modules/forgot-password-success";

const AuthForgotPasswordSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.forgotPasswordSuccess.metaTitle")} />

      <ForgotPasswordSuccessModule />
    </>
  );
};

export default AuthForgotPasswordSuccessPage;
