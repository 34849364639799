import { useState } from "react";
import { User } from "../../../entities/user";
import MerchantUserRepository from "../../../repositories/merchant/user-repository";
import {
  handleDeleteDataResponse,
  handleLoadDataErrorResponse,
} from "../../../helpers";
import { TFunction } from "react-i18next";
import { UserFormValues } from "../form/interface";
import {useIsAdmin} from "../../../context/auth";
import AdminUserRepository from "../../../repositories/admin/user-repository";

const useFormUser = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [data, setData] = useState<User>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitData = (data: UserFormValues) => {
    setSubmitLoading(true);

    return new Promise((resolve, reject) => {
      (isAdmin ? AdminUserRepository : MerchantUserRepository).create(data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    });
  };

  const getData = (id: number) => {
    setIsFetchingData(true);

    (isAdmin ? AdminUserRepository : MerchantUserRepository).findOne(id, {
      with: ["roles"],
    })
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData);
      })
      .catch(() => {
        setIsErrorOnFetching(true);
        handleLoadDataErrorResponse(t, t("text.user"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const deleteData = (id: number) => {
    setDeleteLoading(true);

    return new Promise((resolve, reject) => {
      (isAdmin ? AdminUserRepository : MerchantUserRepository).delete(id)
        .then(() => {
          handleDeleteDataResponse(t, t("text.user"));
          resolve(true);
        })
        .catch((error) => {
          handleDeleteDataResponse(t, t("text.user"), false);
          reject(error);
        })
        .finally(() => {
          setDeleteLoading(false);
        });
    });
  };

  return {
    data,
    submitData,
    submitLoading,
    isFetchingData,
    isErrorOnFetching,
    getData,
    deleteData,
    deleteLoading,
  };
};

export default useFormUser;
