import { CSSObject, MantineTheme } from "@mantine/core";

export const stySidebarBoxContainer = (): CSSObject => ({
  display: "flex",
  minHeight: "100vh",
  "@media (max-width: 768px)": {
    minHeight: "calc(100vh - 60px)",
  },
});

export const styOutletContainer = (theme: MantineTheme): CSSObject => ({
  backgroundColor:
    theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
  width: "100%",
  marginLeft: 300,

  "@media (max-width: 768px)": {
    marginLeft: 0,
  },
});
