import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    title: Yup.string().required().label(t("form.title.label")),
    photo_id: Yup.mixed().required().label(t("form.image.label")),
    date: Yup.string().required().label(t("form.date.label")),

    content: Yup.string().label(t("form.content.label")),
    is_active: Yup.string().label(t("form.active.label")),
  };

  return Yup.object().shape(shapes);
};
