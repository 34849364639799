import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    email: Yup.string().email().required().label(t("form.email.label")),
    password: Yup.string().required().label(t("form.password.label")),
    name: Yup.string().required().label(t("form.name.label")),
  };

  return Yup.object().shape(shapes);
};
