import api from "../services/api";
import { BaseRepository } from "../entities/repository";
import { Article } from "../entities/article";
import { createCancelTokenHandler } from "../services/cancel-token.service";
import { ArticleFormValues } from "../modules/article/form/interface";

const ArticleRepository: BaseRepository<ArticleFormValues, Article> = {
  findAll: function (params) {
    return api.get(`/api/merchant/article`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/merchant/article/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  create(payload) {
    return api.post(`/api/merchant/article`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update(id, payload) {
    return api.put(`/api/merchant/article/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id) {
    return api.delete(`/api/merchant/article/${id}`, {
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ArticleRepository);

export default ArticleRepository;
