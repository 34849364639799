import { useState } from "react";
import { handleLoadDataErrorResponse } from "../../../helpers";
import { TFunction } from "react-i18next";
import MerchantOrderRepository from "../../../repositories/merchant/order-repository";
import { Order } from "../../../entities/order/order";
import {useIsAdmin} from "../../../context/auth";
import AdminOrderRepository from "../../../repositories/admin/order-repository";

const useFormOrder = (t: TFunction) => {
  const isAdmin = useIsAdmin()
  const [data, setData] = useState<Order>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);

  const getData = (id: number) => {
    setIsFetchingData(true);

    (isAdmin ? AdminOrderRepository : MerchantOrderRepository).findOne(id, {
      with: ["customer", "variant.product", "promo", "payment", "status", "merchant"],
    })
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData);
      })
      .catch(() => {
        setIsErrorOnFetching(true);
        handleLoadDataErrorResponse(t, t("text.order"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  return {
    data,
    isFetchingData,
    isErrorOnFetching,
    getData,
  };
};

export default useFormOrder;
