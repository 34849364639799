import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import OrderFormModule from "../../../../modules/order/form";

const AppOrderFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <VuiHelmet title={t("pages.order.detail.metaTitle")} />

      <OrderFormModule id={id} />
    </>
  );
};

export default AppOrderFormPage;
