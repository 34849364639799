import { TFunction } from "react-i18next";
import { SidebarMenuItemProps } from "../sidebar-menu-item/interface";
import {
  IconHome,
  IconKey,
  IconLayout2,
  IconNews,
  IconPacman,
  IconSettings,
  IconShoppingCart,
  IconTicket,
  IconUser,
  IconUserCheck,
  IconUsers,
  IconBuilding, IconReportAnalytics
} from "@tabler/icons";
import { SidebarSection, SidebarRoles } from "./interface";
import * as _ from 'lodash';

export const generateSidebarMenu = (t: TFunction): SidebarMenuItemProps[] => {
  return [
    {
      url: "/home",
      label: t("sidebar.home"),
      icon: IconHome,
      section: "system",
      roles: ['Administrator']
    },
    {
      url: "/page",
      label: t("sidebar.page"),
      icon: IconLayout2,
      section: "system",
      roles: ['Merchant']
    },
    {
      url: "/merchant",
      label: t("sidebar.merchant"),
      icon: IconBuilding,
      section: "system",
      roles: ['Administrator']
    },
    {
      url: "/order",
      label: t("sidebar.order"),
      icon: IconShoppingCart,
      section: "system",
      roles: ['Administrator', 'Merchant']
    },
    {
      url: "/product",
      label: t("sidebar.product"),
      icon: IconPacman,
      section: "system",
      roles: ['Administrator', 'Merchant']
    },
    {
      url: "/promo",
      label: t("sidebar.promo"),
      icon: IconTicket,
      section: "system",
      roles: ['Administrator', 'Merchant']
    },
    {
      url: "/customer",
      label: t("sidebar.customer"),
      icon: IconUserCheck,
      section: "system",
      roles: ['Administrator', 'Merchant']
    },
    {
      url: "/article",
      label: t("sidebar.article"),
      icon: IconNews,
      section: "system",
      roles: ['Merchant']
    },
    {
      url: "/user",
      label: t("sidebar.user"),
      icon: IconUsers,
      section: "system",
      roles: ['Administrator', 'Merchant']
    },
    {
      url: "/report/merchant",
      label: t("sidebar.report"),
      icon: IconReportAnalytics,
      section: "system",
      roles: ['Administrator']
    },
    {
      url: "/setting",
      label: t("sidebar.setting"),
      icon: IconSettings,
      section: "system",
      roles: ['Merchant']
    },
    {
      url: "/profile",
      label: t("sidebar.profile"),
      icon: IconUser,
      section: "account",
      roles: ['Administrator', 'Merchant']
    },
    {
      url: "/change-password",
      label: t("sidebar.changePassword"),
      icon: IconKey,
      section: "account",
      roles: ['Administrator', 'Merchant']
    },
  ];
};

export const getSidebarSectionFromUrl = (
  routes: SidebarMenuItemProps[],
  pathname: string
): SidebarSection => {
  const findMenuByPath = routes.find((route) => {
    if (route.children && route.children.length > 0) {
      return route.children.find((child) => child.url === pathname);
    }
    return route.url === pathname;
  });

  return findMenuByPath && pathname ? findMenuByPath.section : "system";
};

export const filterSidebarSection = (
    menus: SidebarMenuItemProps[],
    section: SidebarSection,
    role: SidebarRoles | undefined
) => {
  return menus.filter((menu) => _.indexOf(menu.roles, role) !== -1).filter((menu) => menu.section === section)
}