import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  imageBox: {
    position: "relative",
    width: "fit-content",
    ".mantine-ActionIcon-root": {
      opacity: 0,
      position: "absolute",
      visibility: "hidden",
      left: "50%",
      content: '""',
      top: "50%",
      transform: "translate(-50%, -50%)",
      transition: " .3s ease-in-out",
    },
    "& .mantine-Image-root": {
      position: "relative",
      transition: " .3s ease-in-out",
    },
    "&:hover": {
      "& .mantine-Image-root": {
        "&:before": {
          position: "absolute",
          content: '""',
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderRadius: 4,
          zIndex: 1,
        },
      },
      "& .mantine-ActionIcon-root": {
        zIndex: 2,
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  imageBoxDisabled: {
    "&:hover": {
      cursor: "not-allowed",
      "& .mantine-Image-root": {
        "&:before": {
          zIndex: -1,
          backgroundColor: "unset",
        },
      },
      "& .mantine-ActionIcon-root": {
        zIndex: -1,
        opacity: 0,
        visibility: "hidden",
      },
    },
  },
  innerGroup: {
    pointerEvents: "none",
  },
  hideDropzone: {
    visibility: "hidden",
    height: 0,
    padding: 0,
    margin: 0,
    border: 0,
    width: 0,
  },
  label: {
    width: "fit-content",
    fontWeight: 500,
  },
  removeText: {
    cursor: "pointer",
  },
  disabled: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
    cursor: "not-allowed",

    "& *": {
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[5],
    },
  },
}));
