import api from "../services/api";
import { createCancelTokenHandler } from "../services/cancel-token.service";
import { AxiosPromise } from "axios";
import { ChangePasswordFormValues } from "../modules/change-password/interface";

export interface IAccountRepository {
  changePassword(
    payload: ChangePasswordFormValues
  ): AxiosPromise<ChangePasswordFormValues>;
}

const AccountRepository: IAccountRepository = {
  changePassword(payload) {
    return api.post("/api/account/update-password", payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.changePassword.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AccountRepository);

export default AccountRepository;
