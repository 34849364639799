import { VuiHelmetProps } from "./interface";
import { Helmet } from "react-helmet-async";
import { APP_NAME } from "../../config";

const VuiHelmet = (props: VuiHelmetProps) => {
  const { title } = props;

  return (
    <Helmet>
      <title>
        {title} | {APP_NAME}
      </title>
    </Helmet>
  );
};

export default VuiHelmet;
