import { Center } from "@mantine/core";
import { PacmanLoader } from "react-spinners";
import { styLoadingWrapper } from "./style";

const VuiScreenLoader = () => {
  return (
    <Center sx={styLoadingWrapper}>
      <PacmanLoader color="#5451DB" />
    </Center>
  );
};

export default VuiScreenLoader;
