import { Button, Group, MediaQuery } from "@mantine/core";
import VuiTable from "../../components/vui-table";
import { TableColumn } from "../../components/vui-table/interface";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VuiSearch from "../../components/vui-search";
import { useDebounce } from "usehooks-ts";
import { DEFAULT_PER_PAGE } from "../../constant";
import { Link } from "react-router-dom";
import VuiHeaderPage from "../../components/vui-header-page";
import useGetPage from "./hooks/useGetPage";

const PageModule = () => {
  const { t } = useTranslation();
  const { dataSource, loadData, loading, total } = useGetPage(t);
  const loadDataRef = useRef(loadData);

  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchValue, 250);

  const columns: TableColumn[] = [
    { key: "name", dataIndex: "name", title: t("text.name") },
    {
      key: "action",
      dataIndex: "url",
      title: "",
      render: (value) => (
        <Group position="right" spacing="xs">
          <Button
            variant="outline"
            to={`/page/${value}`}
            size="xs"
            component={Link}
          >
            {t("button.detail")}
          </Button>
        </Group>
      ),
    },
  ];

  const handleChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  useEffect(() => {
    loadDataRef.current({
      page: page,
      per_page: DEFAULT_PER_PAGE,
      search: debouncedValue,
    });
  }, [debouncedValue, page]);

  return (
    <>
      <VuiHeaderPage title={t("pages.page.title")} />

      <Group position="apart" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSearch value={searchValue} onChange={handleSearch} />
        </MediaQuery>
      </Group>

      <VuiTable
        totalPage={total}
        onChangePage={handleChangePage}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
};

export default PageModule;
