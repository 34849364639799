import { PasswordInput, Skeleton } from "@mantine/core";
import { VuiPasswordInputProps } from "./interface";

const VuiPasswordInput = (props: VuiPasswordInputProps) => {
  const { skeletonLoading = false, ...inputProps } = props;

  return (
    <Skeleton visible={skeletonLoading}>
      <PasswordInput {...inputProps} />
    </Skeleton>
  );
};

export default VuiPasswordInput;
