import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ArticleModule from "../../../modules/article";

const AppArticlePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.article.metaTitle")} />

      <ArticleModule />
    </>
  );
};

export default AppArticlePage;
