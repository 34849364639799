import { Paper, Radio, Stack } from "@mantine/core";
import { PromoFormModuleProps, PromoFormValues } from "./interface";
import VuiHeaderPage from "../../../components/vui-header-page";
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import VuiActionForm from "../../../components/vui-action-form";
import useFormPromo from "../hooks/useFormPromo";
import { EnumPromoDiscountType, EnumPromoType } from "../../../entities/promo";
import VuiTextInput from "../../../components/vui-text-input";
import VuiNumberInput from "../../../components/vui-number-input";
import VuiDateRangePicker from "../../../components/vui-date-range-picker";
import { IconPercentage } from "@tabler/icons";
import VuiCurrencyInput from "../../../components/vui-currency-input";
import VuiMultiSelect from "../../../components/vui-multi-select";
import { backendDate } from "../../../helpers";
import useGetSelectOption from "../../../hooks/useGetSelectOption";
import { validationSchema } from "./validation";
import {useIsAdmin} from "../../../context/auth";

enum ProductValue {
  ALL = "all",
  PICK = "pick",
}

type ProductValueType = ProductValue.ALL | ProductValue.PICK;

const PromoFormModule = (props: PromoFormModuleProps) => {
  const { id } = props;
  const { t } = useTranslation();
  const isAdmin = useIsAdmin()

  const { data, getData, isFetchingData, submitData, submitLoading } =
    useFormPromo(t);
  const getDataRef = useRef(getData);

  const {
    loadProductData,
    productData,
    paymentMethodData,
    loadPaymentMethodData,
  } = useGetSelectOption();
  const loadProductDataRef = useRef(loadProductData);
  const loadPaymentMethodDataRef = useRef(loadPaymentMethodData);

  const [hasInitialized, setHasInitialized] = useState<boolean>(false);
  const [productValue, setProductValue] = useState<ProductValueType>(
    ProductValue.PICK
  );

  const form = useForm<PromoFormValues>({
    initialValues: {
      code: "",
      discount_amount: 0,
      promo_item_ids: [],
      percentage: 0,
      date_from: new Date(),
      date_to: new Date(),
      discount_type_id: EnumPromoDiscountType.PERCENTAGE,
      max_discount: 0,
      name: "",
      max_used: 0,
      type_id: EnumPromoType.PRODUCT,
      period: [new Date(), new Date()],
    },

    validate: yupResolver(validationSchema(t)),
  });

  const handleSubmit = useCallback(


    (values: PromoFormValues) => {
      const normalizeValue: PromoFormValues = {
        type_id: values.type_id,
        code: values.code,
        name: values.name,
        max_used: values.max_used,
        discount_type_id: values.discount_type_id,
        promo_item_ids:
          productValue === ProductValue.ALL ? ["all"] : values.promo_item_ids,
      };

      if (values.period) {
        Object.assign(normalizeValue, {
          date_from: backendDate(values.period[0] as Date),
          date_to: backendDate(values.period[1] as Date),
        });
      }

      if (values.discount_type_id === EnumPromoDiscountType.AMOUNT) {
        Object.assign(normalizeValue, {
          discount_amount: values.discount_amount,
        });
      }

      if (values.discount_type_id === EnumPromoDiscountType.PERCENTAGE) {
        Object.assign(normalizeValue, {
          percentage: values.percentage,
          max_discount: values.max_discount,
        });
      }

      if (id) {
        Object.assign(normalizeValue, {
          id: id,
        });
      }

      submitData(normalizeValue, form.setFieldError);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [productValue, t, id]
  );

  useMemo(() => {
    (async () => {
      if (!hasInitialized) {
        await loadProductDataRef.current();
        await loadPaymentMethodDataRef.current();

        setHasInitialized(true);
      }

      if (id) {
        await getDataRef.current(Number(id));
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      if (productData.length === data.promo_item_ids.length) {
        setProductValue(ProductValue.ALL);
      }

      form.setValues({
        ...data,
        period: [
          new Date(data.date_from as string),
          new Date(data.date_to as string),
        ],
        type_id: String(data.type_id) as EnumPromoType,
        discount_type_id: String(
          data.discount_type_id
        ) as EnumPromoDiscountType,
        discount_amount: data.discount_amount ?? 0,
        percentage: data.percentage ?? 0,
        max_discount: data.max_discount ?? 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangePromoType = useCallback(
    (value: string) => {
      form.setFieldValue("promo_item_ids", []);
      form.setFieldValue("type_id", value as EnumPromoType);
    },
    [form]
  );

  return (
    <>
      <VuiHeaderPage title={t("pages.promo.detail.title")} backUrl={"/promo"} />

      <Paper shadow="xs" radius="md" p="xl">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            {
              isAdmin ? (
                  <VuiTextInput
                      label={t("form.merchant.label")}
                      placeholder={t("form.merchant.placeholder")}
                      skeletonLoading={isFetchingData}
                      disabled={isAdmin}
                      {...form.getInputProps("merchant.name")}
                  />
              ) : ''
            }

            <Radio.Group
              label={t("form.type.label")}
              onChange={handleChangePromoType}
              value={form.values.type_id}

            >
              <Radio value={EnumPromoType.PRODUCT} disabled={isAdmin} label={t("text.variant")} />
              <Radio
                value={EnumPromoType.PAYMENT_METHOD}
                disabled={isAdmin}
                label={t("text.paymentMethod")}
              />
            </Radio.Group>

            <VuiTextInput
              label={t("form.code.label")}
              placeholder={t("form.code.placeholder")}
              skeletonLoading={isFetchingData}
              disabled={isAdmin}
              {...form.getInputProps("code")}
            />

            <VuiTextInput
              label={t("form.name.label")}
              placeholder={t("form.name.placeholder")}
              skeletonLoading={isFetchingData}
              disabled={isAdmin}
              {...form.getInputProps("name")}
            />

            <VuiDateRangePicker
              label={t("form.period.label")}
              placeholder={t("form.period.placeholder")}
              skeletonLoading={isFetchingData}
              disabled={isAdmin}
              {...form.getInputProps("period")}
            />

            <VuiNumberInput
              label={t("form.maximumUsed.label")}
              placeholder={t("form.maximumUsed.placeholder")}
              skeletonLoading={isFetchingData}
              disabled={isAdmin}
              {...form.getInputProps("max_used")}
            />

            <Radio.Group
              label={t("form.discountType.label")}
              {...form.getInputProps("discount_type_id")}
            >
              <Radio
                value={EnumPromoDiscountType.PERCENTAGE}
                label={t("text.percentage")}
                disabled={isAdmin}
              />
              <Radio
                value={EnumPromoDiscountType.AMOUNT}
                label={t("text.amount")}
                disabled={isAdmin}
              />
            </Radio.Group>

            {form.values.discount_type_id ===
              EnumPromoDiscountType.PERCENTAGE && (
              <>
                <VuiNumberInput
                  label={t("form.percentage.label")}
                  placeholder={t("form.percentage.placeholder")}
                  skeletonLoading={isFetchingData}
                  max={100}
                  min={0}
                  icon={<IconPercentage size={18} />}
                  disabled={isAdmin}
                  {...form.getInputProps("percentage")}
                />

                <VuiCurrencyInput
                  label={t("form.maximumDiscount.label")}
                  placeholder={t("form.maximumDiscount.placeholder")}
                  skeletonLoading={isFetchingData}
                  disabled={isAdmin}
                  {...form.getInputProps("max_discount")}
                />
              </>
            )}

            {form.values.discount_type_id === EnumPromoDiscountType.AMOUNT && (
              <VuiCurrencyInput
                label={t("form.discountAmount.label")}
                placeholder={t("form.discountAmount.placeholder")}
                skeletonLoading={isFetchingData}
                disabled={isAdmin}
                {...form.getInputProps("discount_amount")}
              />
            )}

            <Radio.Group
              label={t("form.product.label")}
              value={productValue}
              onChange={(value) => setProductValue(value as ProductValueType)}
            >
              <Radio
                value={ProductValue.PICK}
                label={t("text.selectProduct")}
                disabled={isAdmin}
              />
              <Radio value={ProductValue.ALL} label={t("text.allProduct")} disabled={isAdmin}/>
            </Radio.Group>

            {productValue === ProductValue.PICK && (
              <VuiMultiSelect
                placeholder={t("select.product")}
                skeletonLoading={isFetchingData}
                searchable={true}
                data={
                  form.values.type_id === EnumPromoType.PAYMENT_METHOD
                    ? paymentMethodData
                    : productData
                }
                disabled={isAdmin}
                {...form.getInputProps("promo_item_ids")}
              />
            )}
          </Stack>

          {
            !isAdmin ? (
                <VuiActionForm backUrl="/promo" submitBtnLoading={submitLoading} />
            ) : ''
          }

        </form>
      </Paper>
    </>
  );
};

export default PromoFormModule;
