import {TFunction} from "react-i18next";
import {useState} from "react";
import AdminMerchantRepository from "../../../repositories/admin/merchant-repository";
import {MerchantFormValues} from "../form/interface";
import {Merchant} from "../../../entities/merchant";
import {handleDeleteDataResponse, handleLoadDataErrorResponse} from "../../../helpers";


const useFormMerchant = (t: TFunction) => {
    const [data, setData] = useState<Merchant>();
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isErrorOnFetching, setIsErrorOnFetching] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const submitData = (data: Partial<MerchantFormValues>) => {
        setSubmitLoading(true);

        return new Promise((resolve, reject) => {
            (data.id ? AdminMerchantRepository.update(data.id as string, data) : AdminMerchantRepository.create(data))
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    setSubmitLoading(false);
                });
        });
    };

    const getData = (id: number) => {
        setIsFetchingData(true);

        AdminMerchantRepository.findOne(id, {
            with: ["photo"],
        })
            .then((response) => {
                const { data: responseData } = response.data;
                setData(responseData);
            })
            .catch(() => {
                setIsErrorOnFetching(true);
                handleLoadDataErrorResponse(t, t("text.merchant"));
            })
            .finally(() => {
                setIsFetchingData(false);
            });
    };

    const deleteData = (id: number) => {
        setDeleteLoading(true);

        return new Promise((resolve, reject) => {
            AdminMerchantRepository.delete(id)
                .then(() => {
                    handleDeleteDataResponse(t, t("text.merchant"));
                    resolve(true);
                })
                .catch((error) => {
                    handleDeleteDataResponse(t, t("text.merchant"), false);
                    reject(error);
                })
                .finally(() => {
                    setDeleteLoading(false);
                });
        });
    };

    return {
        data,
        submitData,
        submitLoading,
        isFetchingData,
        isErrorOnFetching,
        getData,
        deleteData,
        deleteLoading,
    };
}

export default useFormMerchant