import { Box, Skeleton, Switch, Text } from "@mantine/core";
import { useStyles } from "./style";
import { VuiSwitchProps } from "./interface";

const VuiSwitch = (props: VuiSwitchProps) => {
  const { formLabel, skeletonLoading = false, size = "md", checked, ...other } = props;
  const { classes } = useStyles();

  return (
    <Skeleton visible={skeletonLoading}>
      <Box>
        {formLabel && <Text className={classes.label}>{formLabel}</Text>}

        <Switch {...other} checked={Boolean((props.value ? props.value : checked))} size={size} />
      </Box>
    </Skeleton>
  );
};

export default VuiSwitch;
