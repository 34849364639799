import SettingRepository from "../../../repositories/setting-repository";
import { useState } from "react";
import { Setting } from "../../../entities/setting";
import { handleLoadDataErrorResponse } from "../../../helpers";
import { TFunction } from "react-i18next";

export const useGetSetting = (t: TFunction) => {
  const [data, setData] = useState<Setting>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const getData = () => {
    setIsFetchingData(true);

    SettingRepository.getSetting({
      with: ["logo", "logoMobile"],
    })
      .then((response) => {
        const { data: responseData } = response.data;
        setData(responseData);
      })
      .catch(() => {
        handleLoadDataErrorResponse(t, t("text.setting"));
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  return {
    getData,
    data,
    isFetchingData,
  };
};
