import {
    BaseRepository,
    IFindAllRepository,
    IFindOneRepository,
    ISelectRepository,
    KeyString
} from "../../entities/repository";
import {createCancelTokenHandler} from "../../services/cancel-token.service";
import api from "../../services/api";
import {AxiosPromise} from "axios";
import {BaseQueryParams} from "../../entities/query-params";

interface IAdminMerchantRepository<T,  R = T> {
    merchantProfit(params?: BaseQueryParams | KeyString): AxiosPromise<any>;
}

const AdminReportMerchantRepository: IAdminMerchantRepository<any> = {
    merchantProfit(params?: BaseQueryParams | KeyString): AxiosPromise<any> {
        return api.get(`/api/admin/report/merchant-profit`, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.merchantProfit.name].handleRequestCancellation()
                .token,
        });
    }
}

const cancelTokenHandlerObject = createCancelTokenHandler(AdminReportMerchantRepository);

export default AdminReportMerchantRepository