import VuiHelmet from "../../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ProductFormModule from "../../../../modules/product/form";

const AppProductFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <VuiHelmet title={t("pages.product.detail.metaTitle")} />

      <ProductFormModule id={id} />
    </>
  );
};

export default AppProductFormPage;
