import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import ResetPasswordModule from "../../../modules/reset-password";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const AuthResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const email = url.searchParams.get("email");

  useEffect(() => {
    if (!token && !email) {
      navigateRef.current("/auth/login");
    }
  }, [token, email]);

  return (
    <>
      <VuiHelmet title={t("pages.forgotPassword.metaTitle")} />

      {token && email && <ResetPasswordModule token={token} email={email} />}
    </>
  );
};

export default AuthResetPasswordPage;
