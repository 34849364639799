import VuiHelmet from "../../../components/vui-helmet";
import { useTranslation } from "react-i18next";
import OrderModule from "../../../modules/order";

const AppOrderPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.order.metaTitle")} />

      <OrderModule />
    </>
  );
};

export default AppOrderPage;
