import api from "../services/api";
import { createCancelTokenHandler } from "../services/cancel-token.service";
import { ICreateRepository } from "../entities/repository";
import { Media } from "../entities/media";

export interface IMediaRepository extends ICreateRepository<FormData, Media> {}

const MediaRepository: IMediaRepository = {
  create(payload) {
    return api.post("/api/media", payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(MediaRepository);

export default MediaRepository;
