import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../../components/vui-helmet";
import TermsConditionModule from "../../../../modules/page/terms-condition";

const AppTermsConditionPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.termsCondition.metaTitle")} />

      <TermsConditionModule />
    </>
  );
};

export default AppTermsConditionPage;
