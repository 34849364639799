import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/vui-helmet";
import ChangePasswordModule from "../../../modules/change-password";

const AppChangePasswordPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.changePassword.metaTitle")} />

      <ChangePasswordModule />
    </>
  );
};

export default AppChangePasswordPage;
