import NumberFormat, { NumberFormatProps } from "react-number-format";

const VuiNumberFormat = (props: NumberFormatProps) => {
  const {
    thousandSeparator = ".",
    decimalSeparator = ",",
    displayType = "text",
    prefix = "Rp ",
    decimalScale = 0,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
    />
  );
};

export default VuiNumberFormat;
