import moment from "moment";

export const backendDate = (date: Date | string | null) => {
  return moment(date).format("YYYY-MM-DD");
};

export const frontendDate = (date: Date | string) => {
  return moment(date).format("DD MMMM YYYY");
};

export const subtractDays = (numOfDays: number, date = new Date()): Date => {
  date.setDate(date.getDate() - numOfDays);
  return date;
};
