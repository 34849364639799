import { Navigate, RouteObject } from "react-router-dom";
import AuthLoginPage from "../pages/auth/login";
import AuthForgotPasswordPage from "../pages/auth/forgot-password";
import AuthForgotPasswordSuccessPage from "../pages/auth/forgot-password-success";
import AuthResetPasswordPage from "../pages/auth/reset-password";
import AuthResetPasswordSuccessPage from "../pages/auth/reset-password-success";
import GuestLayout from "../layouts/guest";

const authRoutes: RouteObject[] = [
  {
    path: "auth",
    element: <GuestLayout />,
    children: [
      { path: "", element: <Navigate to={"/login"} /> },
      { path: "login", element: <AuthLoginPage /> },
      { path: "forgot-password", element: <AuthForgotPasswordPage /> },
      {
        path: "forgot-password-success",
        element: <AuthForgotPasswordSuccessPage />,
      },
      { path: "reset-password", element: <AuthResetPasswordPage /> },
      {
        path: "reset-password-success",
        element: <AuthResetPasswordSuccessPage />,
      },
    ],
  },
];

export default authRoutes;
