import api from "../../services/api";
import { ISelectRepository } from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { SelectItem } from "@mantine/core";

interface IMerchantConstantRepository extends ISelectRepository<SelectItem> {}

const MerchantConstantRepository: IMerchantConstantRepository = {
  select: function (params) {
    return api.get(`/api/merchant/select/constant`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(MerchantConstantRepository);

export default MerchantConstantRepository;
