import { Button, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthModule from "../auth";

const ForgotPasswordSuccessModule = () => {
  const { t } = useTranslation();

  return (
    <AuthModule description={t("pages.forgotPasswordSuccess.description")}>
      <Group position="center" mt="xl">
        <Button
          to={"/auth/login"}
          component={Link}
          fullWidth
          size="md"
          type="submit"
        >
          {t("button.backToLogin")}
        </Button>
      </Group>
    </AuthModule>
  );
};

export default ForgotPasswordSuccessModule;
