import { Group, Title, ActionIcon } from "@mantine/core";
import { VuiHeaderPageProps } from "./interface";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { IconArrowBack } from "@tabler/icons";

const VuiHeaderPage = (props: PropsWithChildren<VuiHeaderPageProps>) => {
  const { title, backUrl, children } = props;

  return (
    <Group position="apart" mb="lg">
      <Group>
        {backUrl && (
          <ActionIcon component={Link} to={backUrl}>
            <IconArrowBack size={28} />
          </ActionIcon>
        )}
        <Title order={2}>{title}</Title>
      </Group>

      {children}
    </Group>
  );
};

export default VuiHeaderPage;
