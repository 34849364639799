import { Navigate, RouteObject } from "react-router-dom";
import AppHomePage from "../pages/app/home";
import AppUserPage from "../pages/app/user";
import AppUserFormPage from "../pages/app/user/form";
import AppProfilePage from "../pages/app/profile";
import AppChangePasswordPage from "../pages/app/change-password";
import AppArticlePage from "../pages/app/article";
import AppArticleFormPage from "../pages/app/article/form";
import AppPrivacyPolicyPage from "../pages/app/page/privacy-policy";
import AppPage from "../pages/app/page";
import AppProductPage from "../pages/app/product";
import AppProductFormPage from "../pages/app/product/form";
import AuthorizedLayout from "../layouts/authorized";
import AppPromoPage from "../pages/app/promo";
import AppPromoFormPage from "../pages/app/promo/form";
import AppCustomerPage from "../pages/app/customer";
import AppCustomerFormPage from "../pages/app/customer/form";
import AppTermsConditionPage from "../pages/app/page/terms-condition";
import AppPurchasePage from "../pages/app/page/purchase";
import AppPageHomePage from "../pages/app/page/home";
import AppOrderPage from "../pages/app/order";
import AppOrderFormPage from "../pages/app/order/form";
import AppSettingPage from "../pages/app/setting";
import AppMerchantPage from "../pages/app/merchant";
import AppMerchantFormPage from "../pages/app/merchant/form";
import AppReportMerchantPage from "../pages/app/report/merchant";

const appRoutes: RouteObject[] = [
  {
    path: "",
    element: <AuthorizedLayout />,
    children: [
      { path: "", element: <Navigate to={"/home"} /> },
      { path: "profile", element: <AppProfilePage /> },
      { path: "change-password", element: <AppChangePasswordPage /> },
      { path: "home", element: <AppHomePage /> },
      {
        path: "user",
        children: [
          { path: "", element: <AppUserPage /> },
          { path: "add", element: <AppUserFormPage /> },
          { path: ":id", element: <AppUserFormPage /> },
        ],
      },
      {
        path: "merchant",
        children: [
          { path: "", element: <AppMerchantPage /> },
          { path: "add", element: <AppMerchantFormPage /> },
          { path: ":id", element: <AppMerchantFormPage /> },
        ]
      },
      {
        path: "article",
        children: [
          { path: "", element: <AppArticlePage /> },
          { path: "add", element: <AppArticleFormPage /> },
          { path: ":id", element: <AppArticleFormPage /> },
        ],
      },
      {
        path: "promo",
        children: [
          { path: "", element: <AppPromoPage /> },
          { path: "add", element: <AppPromoFormPage /> },
          { path: ":id", element: <AppPromoFormPage /> },
        ],
      },
      {
        path: "page",
        children: [
          { path: "", element: <AppPage /> },
          { path: "privacy-policy", element: <AppPrivacyPolicyPage /> },
          { path: "terms-condition", element: <AppTermsConditionPage /> },
          { path: "purchase", element: <AppPurchasePage /> },
          { path: "home", element: <AppPageHomePage /> },
        ],
      },
      {
        path: "product",
        children: [
          { path: "", element: <AppProductPage /> },
          { path: ":id", element: <AppProductFormPage /> },
        ],
      },
      {
        path: "customer",
        children: [
          { path: "", element: <AppCustomerPage /> },
          { path: ":id", element: <AppCustomerFormPage /> },
        ],
      },
      {
        path: "order",
        children: [
          { path: "", element: <AppOrderPage /> },
          { path: ":id", element: <AppOrderFormPage /> },
        ],
      },
      { path: "setting", element: <AppSettingPage /> },
      {
        path: "report",
        children: [
          { path: "merchant", element: <AppReportMerchantPage /> }
        ],
      }
    ],
  },
];

export default appRoutes;
