import api from "../../services/api";
import { ISelectRepository } from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { PaymentMethod } from "../../entities/payment-method";

interface IMerchantPaymentMethodRepository extends ISelectRepository<PaymentMethod> {}

const MerchantPaymentMethodRepository: IMerchantPaymentMethodRepository = {
  select: function (params) {
    return api.get(`/api/merchant/select/payment-method`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  MerchantPaymentMethodRepository
);

export default MerchantPaymentMethodRepository;
