import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    meta_title: Yup.string().required().label(t("form.metaTitle.label")),
    meta_keyword: Yup.string().required().label(t("form.metaKeyword.label")),
    meta_description: Yup.string()
      .required()
      .label(t("form.metaDescription.label")),
    content: Yup.string().required().label(t("form.content.label")),
  };

  return Yup.object().shape(shapes);
};
