import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  const shapes = {
    password: Yup.string()
      .min(6, t("validation.minLength", { item: t("form.password.label") }))
      .required()
      .label(t("form.newPassword.label")),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        t("validation.match", { item: t("form.newPasswordConfirmation.label") })
      )
      .min(6, t("validation.minLength", { item: t("form.password.label") }))
      .required()
      .label(t("form.newPasswordConfirmation.label")),
  };

  return Yup.object().shape(shapes);
};
