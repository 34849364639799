import { Button, Group, MediaQuery, SelectItem } from "@mantine/core";
import VuiTable from "../../components/vui-table";
import { TableColumn } from "../../components/vui-table/interface";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VuiSelect from "../../components/vui-select";
import VuiSearch from "../../components/vui-search";
import { useDebounce } from "usehooks-ts";
import { BaseSort, OrderDirection } from "../../entities/query-params";
import { DEFAULT_PER_PAGE } from "../../constant";
import { Link } from "react-router-dom";
import VuiConfirmationModal from "../../components/vui-confirmation-modal";
import useFormUser from "./hooks/useFormArticle";
import VuiHeaderPage from "../../components/vui-header-page";
import { IconPlus } from "@tabler/icons";
import useGetArticle from "./hooks/useGetArticle";
import { frontendDate } from "../../helpers";
import VuiActionTable from "../../components/vui-action-table";
import { Article } from "../../entities/article";

const ArticleModule = () => {
  const { t } = useTranslation();
  const { dataSource, loadData, loading, total } = useGetArticle(t);
  const loadDataRef = useRef(loadData);
  const { deleteData, deleteLoading } = useFormUser(t);

  const sortOptions: SelectItem[] = [
    {
      value: "title-asc",
      label: t("select.sortAsc", { item: t("text.title") }),
    },
    {
      value: "title-desc",
      label: t("select.sortDesc", { item: t("text.title") }),
    },
    {
      value: "date-asc",
      label: t("select.sortAsc", { item: t("text.date") }),
    },
    {
      value: "date-desc",
      label: t("select.sortDesc", { item: t("text.date") }),
    },
  ];

  const [selectedId, setSelectedId] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleCloseConfirmModal = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);
  const handleDelete = useCallback((id: number) => {
    setSelectedId(id);
    setOpenConfirmModal(true);
  }, []);

  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sort, setSort] = useState<BaseSort>({
    order_by: "title",
    sorted_by: "asc",
  });
  const debouncedValue = useDebounce<string>(searchValue, 250);

  const columns: TableColumn<Article>[] = [
    { key: "title", dataIndex: "title", title: t("text.title") },
    {
      key: "date",
      dataIndex: "date",
      title: t("text.date"),
      render: (value) => <>{frontendDate(value)}</>,
    },
    {
      key: "action",
      dataIndex: "id",
      title: "",
      render: (value) => (
        <VuiActionTable
          detailUrl={`/article/${value}`}
          onDelete={() => handleDelete(Number(value))}
          canDelete
        />
      ),
    },
  ];

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
      });
    },
    [debouncedValue, sort]
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleChangeSort = useCallback(
    (value: string) => {
      const splitValue = value.split("-");
      setSort({
        order_by: splitValue[0],
        sorted_by: splitValue[1] as OrderDirection,
      });

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: splitValue[1] as OrderDirection,
        order_by: splitValue[0],
      });
    },
    [debouncedValue, page]
  );

  const handleConfirmDelete = useCallback(() => {
    deleteData(selectedId).then(() => {
      handleCloseConfirmModal();

      loadDataRef.current({
        page: page,
        per_page: DEFAULT_PER_PAGE,
        search: debouncedValue,
        sorted_by: sort.sorted_by,
        order_by: sort.order_by,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, debouncedValue, sort, page]);

  useEffect(() => {
    loadDataRef.current({
      page: 1,
      per_page: DEFAULT_PER_PAGE,
      search: debouncedValue,
      sorted_by: sort.sorted_by,
      order_by: sort.order_by,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <>
      <VuiConfirmationModal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        onSubmitLoading={deleteLoading}
      />

      <VuiHeaderPage title={t("pages.article.title")}>
        <Group>
          <Button
            leftIcon={<IconPlus size={18} />}
            to={"/article/add"}
            component={Link}
          >
            {t("button.add")}
          </Button>
        </Group>
      </VuiHeaderPage>

      <Group position="apart" mb="md">
        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSearch value={searchValue} onChange={handleSearch} />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
          <VuiSelect
            placeholder={t("select.sort")}
            onChange={handleChangeSort}
            data={sortOptions}
          />
        </MediaQuery>
      </Group>

      <VuiTable
        totalPage={total}
        onChangePage={handleChangePage}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
};

export default ArticleModule;
