import { useMantineColorScheme, ActionIcon } from "@mantine/core";
import { BsMoonStars, BsSun } from "react-icons/bs";

const ColorSchemeToggle = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <ActionIcon
      onClick={() => toggleColorScheme()}
      size="xl"
      sx={(theme) => ({
        backgroundColor:
          colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        color:
          colorScheme === "dark"
            ? theme.colors.yellow[4]
            : theme.colors.blue[6],
        boxShadow: theme.shadows.md,
      })}
    >
      {colorScheme === "dark" ? <BsSun size={20} /> : <BsMoonStars size={20} />}
    </ActionIcon>
  );
};

export default ColorSchemeToggle;
