import api from "../../services/api";
import {
  IFindAllRepository,
  IFindOneRepository,
  ISelectRepository,
  IUpdateRepository,
} from "../../entities/repository";
import { Product } from "../../entities/product/product";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { ProductFormValues } from "../../modules/product/form/interface";

interface IAdminProductRepository<T, R = T>
  extends IFindAllRepository<T, R>,
    IUpdateRepository<Partial<ProductFormValues>, R>,
    IFindOneRepository<T, R>,
    ISelectRepository<T> {}

const AdminProductRepository: IAdminProductRepository<Product> = {
  findAll: function (params) {
    return api.get(`/api/admin/product`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`/api/admin/product/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params) {
    return api.get(`/api/admin/select/product`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  update(id, payload) {
    return api.put(`/api/admin/product/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AdminProductRepository);

export default AdminProductRepository;
