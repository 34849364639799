import {
  Box,
  Group,
  Pagination,
  Paper,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { useStyles } from "./style";
import { useMemo } from "react";
import { VuiTableProps } from "./interface";
import VuiLoader from "../vui-loader";
import { useTranslation } from "react-i18next";
import VuiNumberFormat from "../vui-number-format";

const VuiTable = (props: VuiTableProps) => {
  const {
    dataSource,
    columns,
    isLoading = false,
    onChangePage,
    totalPage = 0,
    withPagination = true,
    label = "",
    page = 1,
    withRadius = true,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const renderColumns = useMemo(() => {
    return (
      <tr>
        <th key={`number_th`}>No</th>
        {columns.map((column, index) => (
          <th style={column.styles} key={`${index}_th`}>
            {column.title}
          </th>
        ))}
      </tr>
    );
  }, [columns]);

  const renderBody = useMemo(() => {
    if (dataSource.length < 1) {
      return (
        <tr key={`first-row`} className={classes.noDataWrapper}>
          <td key={"number-column"} colSpan={columns.length + 1}>
            {t("text.dataNotFound")}
          </td>
        </tr>
      );
    }

    return dataSource.map((data, rowIndex) => (
      <tr key={`${rowIndex}-row`}>
        <td key={"number-column"}>{rowIndex + 1}</td>
        {columns.map((column, columnIndex) =>
          column.type === "currency" ? (
            <td key={`${rowIndex}-${columnIndex}-column`}>
              {data[column.dataIndex] ? (
                <VuiNumberFormat value={data[column.dataIndex]} />
              ) : (
                "Rp 0"
              )}
            </td>
          ) : (
            <td key={`${rowIndex}-${columnIndex}-column`}>
              {data[column.dataIndex]
                ? column.render
                  ? column.render(data[column.dataIndex], data)
                  : data[column.dataIndex]
                : "-"}
            </td>
          )
        )}
      </tr>
    ));
  }, [dataSource, columns, classes, t]);

  return (
    <Box>
      {label && <Text sx={{ fontWeight: 500 }}>{label}</Text>}

      <Paper shadow="xs" radius={withRadius ? "sm" : 0}>
        <ScrollArea sx={{ borderRadius: withRadius ? 4 : 0 }}>
          <Table
            className={classes.table}
            verticalSpacing="sm"
            highlightOnHover
          >
            <thead className={classes.header}>{renderColumns}</thead>
            <tbody>
              {isLoading ? (
                <tr className={classes.boxLoader}>
                  <td colSpan={columns.length + 1}>
                    <VuiLoader />
                  </td>
                </tr>
              ) : (
                renderBody
              )}
            </tbody>
          </Table>
        </ScrollArea>
      </Paper>

      {withPagination && (
        <Group position="right" mt="xl">
          <Pagination
            onChange={onChangePage}
            total={totalPage}
            size="lg"
            page={page}
          />
        </Group>
      )}
    </Box>
  );
};

export default VuiTable;
