import axios from "axios";

export const createCancelTokenHandler = (apiObject: any) => {
  const cancelTokenHandler: any = {};
  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    const cancelTokenRequestHandler: any = {
      cancelToken: undefined,
    };

    cancelTokenHandler[propertyName] = {
      handleRequestCancellation: () => {
        if (cancelTokenRequestHandler.cancelToken) {
          cancelTokenRequestHandler.cancelToken.cancel(
            `${propertyName} canceled`
          );
        }

        cancelTokenRequestHandler.cancelToken = axios.CancelToken.source();
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
};
