import { TFunction } from "react-i18next";
import { AxiosError } from "axios";
import _ from "lodash";
import { NotificationType, openNotification } from "./notification.helper";
import { SetFieldError } from "@mantine/form/lib/types";

export const handleLoadDataErrorResponse = (t: TFunction, item: string) => {
  openNotification(
    t("notification.error.invalidLoadItem", {
      item: item,
    }),
    NotificationType.ERROR
  );
};

export const handleSaveErrorResponse = <T>(
  t: TFunction,
  error: AxiosError,
  setFieldError?: SetFieldError<T>
) => {
  const tempErrors: T | undefined = _.get(error, "response.data.errors");
  if (tempErrors && !_.isEmpty(tempErrors)) {
    const flattenError: string[] = Object.values(tempErrors).flat();
    if (setFieldError) {
      Object.entries(tempErrors).map(([key, value]: [string, string[]]) => {
        return setFieldError(key, value);
      });
    }
    if (flattenError.length > 0) {
      openNotification(flattenError[0], NotificationType.ERROR);
    }
  } else {
    const getErrorMessage = _.get(error, "response.data.message", null);
    if (getErrorMessage) {
      openNotification(
        _.get(error, "response.data.message"),
        NotificationType.ERROR
      );
    } else {
      openNotification(t("notification.error.default"), NotificationType.ERROR);
    }
  }
};

export const handleSaveSuccessResponse = (
  t: TFunction,
  item: string,
  isUpdate: boolean = false
) => {
  const checkIsUpdate = isUpdate
    ? "notification.success.updateItem"
    : "notification.success.createItem";

  openNotification(
    t(checkIsUpdate, {
      item: item,
    }),
    NotificationType.SUCCESS
  );
};

export const handleDeleteDataResponse = (
  t: TFunction,
  item: string,
  success: boolean = true
) => {
  if (success) {
    openNotification(
      t("notification.success.deleteItem", {
        item: item,
      }),
      NotificationType.SUCCESS
    );
  } else {
    openNotification(
      t("notification.error.deleteItem", {
        item: item,
      }),
      NotificationType.ERROR
    );
  }
};
