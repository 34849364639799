import { Title, Text, Button, Container, Group } from "@mantine/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>{t("pages.notFound.title")}</Title>
      <Text
        color="dimmed"
        size="lg"
        align="center"
        className={classes.description}
      >
        {t("pages.notFound.description")}
      </Text>
      <Group position="center">
        <Button<typeof Link>
          component={Link}
          to={"/home"}
          variant="subtle"
          size="md"
        >
          {t("button.takeToHomePage")}
        </Button>
      </Group>
    </Container>
  );
};

export default NotFoundPage;
