import { TextInput, TextInputProps } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { useTranslation } from "react-i18next";

const VuiSearch = (props: TextInputProps) => {
  const { t } = useTranslation();
  const {
    icon = <IconSearch size={18} />,
    placeholder = t("text.search"),
    ...other
  } = props;

  return (
    <TextInput
      {...other}
      sx={{
        "@media (min-width: 768px)": {
          minWidth: 360,
        },
      }}
      placeholder={placeholder}
      icon={icon}
    />
  );
};

export default VuiSearch;
