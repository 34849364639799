import api from "../services/api";
import { createCancelTokenHandler } from "../services/cancel-token.service";
import { AxiosPromise } from "axios";
import { ForgotPasswordFormValues } from "../modules/forgot-password/interface";
import { ResetPasswordFormValues } from "../modules/reset-password/interface";

export interface IAuthRepository {
  resetPassword(
    payload: ResetPasswordFormValues
  ): AxiosPromise<ResetPasswordFormValues>;
  forgotPassword(
    payload: ForgotPasswordFormValues
  ): AxiosPromise<ForgotPasswordFormValues>;
}

const AuthRepository: IAuthRepository = {
  resetPassword(payload) {
    return api.post("/api/reset-password", payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.resetPassword.name
        ].handleRequestCancellation().token,
    });
  },
  forgotPassword(payload) {
    return api.post("/api/forgot-password", payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.forgotPassword.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AuthRepository);

export default AuthRepository;
