import api from "../../services/api";
import { ISelectRepository } from "../../entities/repository";
import { createCancelTokenHandler } from "../../services/cancel-token.service";
import { SelectItem } from "@mantine/core";

interface IAdminConstantRepository extends ISelectRepository<SelectItem> {}

const AdminConstantRepository: IAdminConstantRepository = {
  select: function (params) {
    return api.get(`/api/admin/select/constant`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AdminConstantRepository);

export default AdminConstantRepository;
